import { PhaserLayer } from "../../types";
import { Entity, Has, HasValue, getComponentValue, hasComponent, removeComponent, runQuery, setComponent } from "@latticexyz/recs";
import { filter, map, merge } from "rxjs";
import { WorldCoord } from "../../../../../types";
import { worldCoordEq } from "../../../../../utils/coords";
import { pixelToWorldCoord } from "../../utils";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { InputUtils } from "./createInputSystem";
import { manhattan } from "../../../../../utils/distance";

export function registerClicks(layer: PhaserLayer, { getSelectedEntity, getHighlightedEntity }: InputUtils) {
	const {
    components: { HoverHighlight, PreviousMovableHightlight,PreviousHoverHighlight },
    parentLayers: {
      network: {
        components: { UnitType, MatchFinished, Position, MatchTurn },
        utils: { isOwnedByCurrentPlayer, hasPendingAction: _hasAction},
        network: { matchEntity },
      },
      headless: {
        components: { NextPosition, InCurrentMatch, OnCooldown },
        api: { canAttack, attack, calculateMovementPath, getAttackableEntities },
      },
      local: {
        api: { selectArea, resetSelection, move, getPotentialPaths, botReact },
        components: { PotentialPath, LocalPosition, AttackableEntities },
      },
    },
    api: {
      mapInteraction: { mapInteractionEnabled, forceEnableMapInteraction },
    },
    scenes: {
      Main: { input, maps },
    },
  } = layer;
  /**
  * 1. Selectable only for [Unit, Structure] based on SyncSystem
  * 2. Selection is for all coord on the map 
  * 3. Therefore, 3.1 if click to NON-Unit/Structure, then SET selectedEntity = NIL
  *               3.2 if click to Unit/Structure, then SET selectedEntity = Entity 
  *                   --> draw/un-draw the potential path  
  *                   --> Local.PotentialPathSystem -> createDrawPotentialPathSystem 
  * 4. The nextclick, based on current selectedEntity
  *            4.1 if NIL means previous action is clicking on NON-Unit/Structure, then back to 3
  *            4.2 else means previous action is clicking on Unit/Structure [A], then
  *                    [A]----> [x,y] <----[B] 
  * 5.                 |if currently hovering a NON-Unit/Structure (highlightedEntity == null), then click to 
  *                    |     that position will jump to these cases:
  *                    |     5.1 From 5.3 with NextPosition initialized and clicked to same position of NextPosition
  *                    |             contract-move
  *                    |     5.2 NON NextPosition of [A] but have NextPosition of [B]
  *                    |         [A]----> [x,y] <----[B] 
  *                    |             DO NOTHING. That position is occupied
  *                    |     5.3 inside of movement-area (if-3) 
  *                    |             SET NextPosition of [A]---->[x,y]
  *                    |             if NO-Attackable 
  *                    |                 contract-move
  *                    |                 resetSelection
  *                    |             if YES-Attackable
  *                    |                 defer 5.1
  *                    |     5.4 outside of movement-area (if-4) 
  *                    |             CLEAR NextPosition of [A]
  *                    |             resetSelection
  * 6.                 |if currently hovering a Unit/Structure (highlightedEntity != null)
  *                    |     check can attack, then decide to move/moveThenFight
  */
  /**
   * 1st click do nothing rather than setSelectedEntity
   * 2nd click will start tracing the action on the one selected
   *     - Highlighted is the UNIT | STRUCTURE have been currently hovered 
   *     - Highlighted == null means 2nd click to tilemap 
   *          - Move to that tile (if noone commit before) 
   *     - Highlighted != null means 2nd click to UNIT | STRUCTURE (attacking)       
   * @param clickedPosition 
   * @returns 
   */
	
	const onClick = function (clickedPosition: WorldCoord) {
    
    const selectedEntity = getSelectedEntity();
    // there are situations where the entity may have died
    // while being selected
    
    if (selectedEntity && !hasComponent(LocalPosition, selectedEntity)) {
      resetSelection();
      selectArea({ ...clickedPosition, width: 1, height: 1 });
      return;
    }
    // If the player owns the select unit...
    if (selectedEntity && isOwnedByCurrentPlayer(selectedEntity)) {
      const hasPendingAction = _hasAction(selectedEntity);
      const clearNextPosition = !hasPendingAction;
      const highlightedEntity = getHighlightedEntity();
      const currentPosition = getComponentValue(LocalPosition, selectedEntity);
      if (!currentPosition) return;

      // If the player is hovering over an empty tile
      if (highlightedEntity == null) {
        /**
         * [4.1]
         * Hovering on a NON-Unit/Structure, aka something else on map 
         */
        if (hasComponent(OnCooldown, selectedEntity)) {
          resetSelection(clearNextPosition);
          selectArea({ ...clickedPosition, width: 1, height: 1 });
          return;
        }
        const nextPosition = getComponentValue(NextPosition, selectedEntity);
        const nextPositionAtClickedPosition = [
          ...runQuery([
            HasValue(NextPosition, {
              x: clickedPosition.x,
              y: clickedPosition.y,
            }),
          ]),
        ][0];
        if (nextPosition && nextPosition.userCommittedToPosition && worldCoordEq(clickedPosition, nextPosition)) {
          // no-op cause player decide to move anyway
        } else if (!nextPosition && nextPositionAtClickedPosition) {
          // no-op cause other unit comited to this coord
        } else if(
          (!nextPosition || !nextPosition.userCommittedToPosition) &&
          hasComponent(PotentialPath, selectedEntity) &&
          calculateMovementPath(LocalPosition, selectedEntity, currentPosition, clickedPosition).length > 0
        ){
          setComponent(NextPosition, selectedEntity, {
            ...clickedPosition,
            userCommittedToPosition: true,
            intendedTarget: undefined,
          });

          move(selectedEntity, clickedPosition);
          resetSelection(false);
        }else {
           // Clicking outside movement area 
          resetSelection(clearNextPosition);
          selectArea({ ...clickedPosition, width: 1, height: 1 });
        }
      }else {
        const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
        if (!hoverHighlight) return;
        
        const lastMovementAttempt = getComponentValue(PreviousMovableHightlight, selectedEntity)
        const attackableEntities: Entity[] = []
        attackableEntities.push(...(getAttackableEntities(selectedEntity, currentPosition) ?? []))
        if (!!lastMovementAttempt) {
          attackableEntities.push(...(getAttackableEntities(selectedEntity, lastMovementAttempt) ?? []))
        }
        const hoveredAttackableEntity =
          attackableEntities &&
          attackableEntities.find((entity) => worldCoordEq(hoverHighlight, getComponentValue(Position, entity)));
        if (!hoveredAttackableEntity) {return}
        
        if (lastMovementAttempt && manhattan(hoverHighlight, lastMovementAttempt) == 1 && hoveredAttackableEntity) {
            // move and attack
            setComponent(NextPosition, selectedEntity, {
              ...lastMovementAttempt,
              userCommittedToPosition: true,
              intendedTarget: undefined,
            }); 
            move(selectedEntity, lastMovementAttempt, highlightedEntity);
            resetSelection(false); 
        }else if (manhattan(hoverHighlight, currentPosition) == 1) {
          // attack
          attack(selectedEntity, highlightedEntity);
          resetSelection(false);
        }
        else {
          resetSelection();
          selectArea({ ...clickedPosition, width: 1, height: 1 });
        }
      }
    }else {
      resetSelection();
      selectArea({ ...clickedPosition, width: 1, height: 1 });
    }

	}
	
  
  merge(input.click$, input.rightClick$)
    .pipe(
      filter((pointer) => {
        const clickingCanvas = pointer.event.target instanceof HTMLCanvasElement;
        // in case we end up in a situation where the UI has not
        // properly re-enabled map interaction
        if (clickingCanvas && !mapInteractionEnabled()) forceEnableMapInteraction();

        return clickingCanvas;
      }),
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel)),
    )
    .subscribe((coord) => {
      if ([-8,-7,-6].includes(coord.x) && [-4,-5].includes(coord.y)) {
        EndTurn();
      }
      onClick(coord);
    });


    // input.onKeyPress(
    //   (keys) => keys.has("J"),
    //   () => {
    //     console.log("J")
    //     if (!matchEntity) {return }
    //     const finished = getComponentValue(MatchFinished, matchEntity);
    //     if (finished) { return }
    //     const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
    //     if (!hoverHighlight) {
    //       return
    //     }
    //     onClick(hoverHighlight)
    //   }
    // );

    // input.onKeyPress(
    //   (keys) => keys.has("K"),
    //   () => {
    //     console.log("K")
    //     if (!matchEntity) {return }
    //     const finished = getComponentValue(MatchFinished, matchEntity);
    //     if (finished) { return }
    //     const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
    //     if (!hoverHighlight) {
    //       return
    //     }
    //     resetSelection(true);
    //     // selectArea({ ...clickedPosition, width: 1, height: 1 });
    //   }
    // );

    // input.onKeyPress(
    //   (keys) => keys.has("ESC"),
    //   () => {
    //     EndTurn();
    //   }
    // );

    function EndTurn(){
      if (!matchEntity) {return }
      const hasPendingAction = _hasAction(singletonEntity);
      if (hasPendingAction) { return; }
      const finished = getComponentValue(MatchFinished, matchEntity);
      if (finished) { return }
      const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
      if (!hoverHighlight) {
        return
      }
      resetSelection();
      
      const entities = [
        ...runQuery([
          Has(UnitType), Has(LocalPosition), Has(InCurrentMatch)
        ]),
      ]

      const playerUnitEntities = [];
      const playerUnitPositions = [];
      const playerCooldowns = [];
      const botUnitEntites = [];
      const botUnitPositions = [];
      
      for (let i=0; i< entities.length; i++) {
        if (isOwnedByCurrentPlayer(entities[i])) {
          playerUnitEntities.push(entities[i])
          playerUnitPositions.push(getComponentValue(LocalPosition, entities[i]))
          playerCooldowns.push(getComponentValue(OnCooldown, entities[i]))
        }else {
          botUnitEntites.push(entities[i])
          botUnitPositions.push(getComponentValue(LocalPosition, entities[i]))
        }
      }
      
      if (playerUnitPositions.length <= 0) return;
      // const playerPosition = playerUnitPositions[playerUnitPositions.length - 1]
      // const playerEntity = playerUnitEntities[playerUnitEntities.length - 1]
      const botReactPlan = {
        attackers: [] as Entity[],
        targetPositions: [] as {x: number, y: number}[],
        defenders: [] as Entity[],
      }
      const allocatedCoords: { x: number, y: number }[] = [];
      loop: for (let i=0; i< botUnitEntites.length; i++) {
          const position = botUnitPositions[i];
          if (!position) continue
          for (let o=0; o< playerUnitEntities.length; o++) {
            if (canAttack(botUnitEntites[i], playerUnitEntities[o]) && position.y == playerUnitPositions[o].y) {
              botReactPlan.attackers.push(botUnitEntites[i])
              botReactPlan.defenders.push(playerUnitEntities[o])
              botReactPlan.targetPositions.push({x:123456, y:123456})
              resetSelection(false)
              continue loop
            }
          }

          selectArea({ ...position, width: 1, height: 1 });
          const potentialPath = getPotentialPaths(botUnitEntites[i]);
          
          if (!potentialPath || potentialPath.x.length == 0) continue

          let min = 100000000; 
          let index = 0; let indexPlayerUnit = 0;
          for (let o=0; o< playerUnitEntities.length; o++) {
            const playerPosition = playerUnitPositions[o]
            for (let j=0; j< potentialPath?.x.length; j++) {
              // Check if the potential path position is already occupied by another bot unit
              if (botUnitPositions.some(pos => pos.x === potentialPath.x[j] && pos.y === potentialPath.y[j])) {
                continue;
              }
              const howClose = Math.abs(potentialPath.x[j] - playerPosition.x) + Math.abs(potentialPath.y[j] - playerPosition.y)
              if (
                howClose<= min &&
                !allocatedCoords.some(coord => coord.x === potentialPath.x[j] && coord.y === potentialPath.y[j])
              ) {
                if (howClose < min){
                  min = howClose
                  index = j 
                  indexPlayerUnit = o
                }else if (potentialPath.y[j] == playerPosition.y && potentialPath.x[j] > playerPosition.x) {
                  index = j 
                  indexPlayerUnit = o
                }
              }
            }              
          }
          const clickedPosition = {x: potentialPath.x[index], y: potentialPath.y[index]}
          setComponent(NextPosition, botUnitEntites[i], {
            ...clickedPosition,
            userCommittedToPosition: true,
            intendedTarget: undefined,
          });

          allocatedCoords.push(clickedPosition)
          if (min > 1) {
            botReactPlan.attackers.push(botUnitEntites[i])
            botReactPlan.defenders.push("" as Entity)
            botReactPlan.targetPositions.push(clickedPosition)
          }else {
            botReactPlan.attackers.push(botUnitEntites[i])
            botReactPlan.defenders.push(playerUnitEntities[indexPlayerUnit])
            botReactPlan.targetPositions.push(clickedPosition)
          }
          for(let i =0; i< playerUnitEntities.length; i++) {
            setComponent(OnCooldown, playerUnitEntities[i], {value: true})
          }
          const currentMatch = getComponentValue(MatchTurn, matchEntity);
          setTimeout(() => {
            const latestMatch = getComponentValue(MatchTurn, matchEntity);
            if (currentMatch?.value != latestMatch?.value) {
              return
            }
            for(let i =0; i< playerUnitEntities.length; i++) {
              if (playerCooldowns[i]?.value) {
                setComponent(OnCooldown, playerUnitEntities[i], {value: true})
              } else {
                removeComponent(OnCooldown, playerUnitEntities[i])
              }
            }
          }, 2000)
          resetSelection(false);
      }
      botReact(botReactPlan.attackers, botReactPlan.targetPositions, botReactPlan.defenders);
    }
}

