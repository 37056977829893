import { useAmalgema } from "../../../useAmalgema";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";
import { Entity, Has, HasValue, Not, getComponentValue, runQuery } from "@latticexyz/recs";
import { Button } from "../../ui/Theme/FireEmblem/Button";
import { Card } from "../../ui/Theme/FireEmblem/Card";
import { Plus } from "../../ui/Theme/FireEmblem/Icons/Plus";
import { decodeEntity, encodeEntity, singletonEntity } from "@latticexyz/store-sync/recs";
import { OpenMatches } from "./OpenMatches";
import { createMatchEntity } from "../../../createMatchEntity";
import { MAP_SYSTEM_ID, MATCH_SYSTEM_ID, PLAYER_SYSTEM_ID, NFT_SYSTEM_ID } from "../../../constants";
import { Hex, padHex, stringToHex } from "viem";
import { useNetworkLayer } from "../../useNetworkLayer";
import { useEffect, useState } from "react";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import { encodeSystemCalls } from "@latticexyz/world/internal";
import { getMatchUrl } from "../../../getMatchUrl";
import RadialArcMenu from "./RadialArcMenu";
import MatchItems from "./MatchItems";
import { ToastContainer, toast } from 'react-toastify';
import { addressToEntityID } from "../../../mud/setupNetwork";
export function MatchTable() {
	
	const {
		executeSystem,
		utils: { getLevelSpawns, getItemsData },
		components: { MatchConfig, MatchFinished, MatchReady, Items, UnitItems},
	} = useAmalgema();

	const { externalWalletClient } = useAmalgema();
	if (externalWalletClient == null) { return <></> }
	// encodeEntity({ address: "address" }, { address: (externalWalletClient.account.address as Hex) })
	const openMatches = useEntityQuery([
    Has(MatchConfig),
		HasValue(MatchConfig, {createdBy: encodeEntity({ address: "address" }, { address: (externalWalletClient.account.address as Hex) })}),
    // HasValue(MatchJoinable, { value: true }),
    Has(MatchReady),
    Not(MatchFinished),
  ]);
	const joinableMatches = openMatches
  const { openConnectModal } = useConnectModal();
	const [createMatch, setCreateMatch] = useState<boolean>(false)
	const matchToast = () => {
    const toastID = toast("Creating a new match...", {
      isLoading: true,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      position: "top-center",
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: { background: '#4CAF50' },
    });
		return toastID
  }

	useEffect(() => {
		if (createMatch) {
			if (joinableMatches.length > 0) {
				window.location.href = getMatchUrl(joinableMatches[joinableMatches.length-1])
				return
			}
			const toastID = matchToast()
			const matchEntity = createMatchEntity();
			const spawnsInLevel = getLevelSpawns(stringToHex("Simple", { size: 32 }));
			
			try {
				console.log(`Creating match ${matchEntity} ...`);
				const systemCalls = [
					{
						systemId: NFT_SYSTEM_ID,
						functionName: "bootstrap",
						args: [],
					},
					{
						systemId: MATCH_SYSTEM_ID,
						functionName: "createMatch",
						args: [
							matchEntity,
							stringToHex("Simple", { size: 32 }),
						],
					},
					{
						systemId: PLAYER_SYSTEM_ID,
						functionName: "startMatch",
						args: [matchEntity as Hex],
					},
					{
						systemId: MAP_SYSTEM_ID,
						functionName: "instantiateMap",
						args: [matchEntity],
					},
				];

				executeSystem({
					systemCall: "batchCall",
					systemId: "CreateMatch",
					args: [[encodeSystemCalls(IWorldAbi, systemCalls).map(([systemId, callData]) => ({ systemId, callData }))], {}],
				}).then(() => {
					toast.update(toastID, {
						render: "Match created successfully!",
						type: "success",
						isLoading: false,
						autoClose: 3000,
						closeButton: false,
						onClose: () => {
							window.location.href = getMatchUrl(matchEntity)
						}
					});
				})
			} catch (e) {
				toast.update(toastID, {
					render: "Error creating match. Please try again.",
					type: "error",
					isLoading: false,
					autoClose: 3000,
					closeButton: false,
				});
				setCreateMatch(false)
				console.log(e);
			}

			console.log(`Created match!`);
		}
	}, [createMatch])

	const onMatchCreate = () => {
		if(!externalWalletClient) {
			if (openConnectModal) openConnectModal();
			return;
		}
		if (!createMatch) {
			setCreateMatch(true)
		}
	}
	const [showMatchItems, setShowMatchItems] = useState(false);

	const toggleMatchItems = () => {
		setShowMatchItems(!showMatchItems);
	}


	const items = useComponentValue(Items, singletonEntity);
	const [itemDatas, setItemDatas] = useState<any[]>([]);
	useEffect(() => {
		if (!items || !items.value || items.value.length <= 0) {
			return
		}
		setItemDatas(getItemsData())
	},[items])

	return (
		<div className="grow flex p-0">
			<div className="flex flex-col grow">
				<ToastContainer style={{ display: "none" }}/>
				<RadialArcMenu onMatchCreate={onMatchCreate} joinableMatches={joinableMatches} itemDatas={itemDatas}/>
			</div>
			<div className="flex pointer-events-auto absolute right-0 z-50">
				<button 
					onClick={toggleMatchItems}
					className="p-3 mr-4 bg-gradient-to-b from-[#71B1AB] to-[#518496] rounded-full border-2 border-[#71B1AB] shadow-lg hover:from-[#71B1AB] hover:to-[#71B1AB] active:from-[#518496] active:to-[#518496] w-16 h-16 text-white font-medieval transition-all duration-200 transform hover:scale-105 flex items-center justify-center text-center text-2xl"
				>
					{showMatchItems ? '▶' : '◀'}
				</button>
				<div 
					className={`w-[400px] shrink-0 transition-all duration-300 ease-in-out transform ${
						showMatchItems ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
					} ${!showMatchItems && 'absolute invisible'}`}
					style={{
						transitionProperty: 'transform, opacity',
						transitionDuration: '300ms'
					}}
				>
					<MatchItems itemDatas={itemDatas} isRandomizing={(createMatch && joinableMatches.length ==0)}/>
				</div>
			</div>
		</div>
	);
}



