
import { UnitTypes, StructureTypes } from "../../Network";
export const TILE_WIDTH = 32;
export const TILE_HEIGHT = 32;

export enum Scenes {
	Main = "Main",
	UI = "UI",
}

export enum Maps {
	Main = "Main",
	Pixel = "Pixel",
	Tactic = "Tactic",
	Strategic = "Strategic",
}

export enum Assets {
	OverworldTileset = "OverworldTileset",
	OverworldTilesetTuxemon = "OverworldTileset-tuxemon",
	OverworldTilesetTinyworld = "OverworldTileset-tinyworld",
	MainAtlas = "MainAtlas",
	Background = "Background",
	TiledBackground = "TiledBackground",
	MinimapBackground = "MinimapBackground",
	CloudBackground = "CloudBackground",
	Cloud1 = "Cloud1",
	Cloud2 = "Cloud2",
	Professor = "Professor",
	Splash = "Splash",
	Gold = "Gold",
	Central = "Central",
	LeftMid = "LeftMid",
	RightMid = "RightMid",
	LeftCornerUpA = "LeftCornerUpA",
	LeftCornerUpB = "LeftCornerUpB",
	RightCornerUpA = "RightCornerUpA",
	RightCornerUpB = "RightCornerUpB",
	UpperEdge = "UpperEdge",
	UpperEdgeLeft = "UpperEdgeLeft",
	UpperEdgeRight = "UpperEdgeRight",
	LeftEdge = "LeftEdge",
	RightEdge = "RightEdge",
}
  

export enum Animations {
	TileSelect = "TileSelect",
	TileSelectEndTurn = "TileSelectEndTurn",
	TileHighlightYellow = "TileHighlightYellow",
	// Structures
	SpawnSettlement = "SpawnSettlement",
	Settlement = "Settlement",
	GoldMine = "GoldMine",

	HalberdierIdle = "HalberdierIdle",
	HalberdierAttack = "HalberdierAttack",

	HeroIdle = "HeroIdle",
	HeroAttack = "HeroAttack",
	HeroAttackRight = "HeroAttackRight",
	HeroAttackLeft = "HeroAttackLeft",
	HeroAttackUp = "HeroAttackUp",
	HeroAttackDown = "HeroAttackDown",
	DruidIdle = "DruidIdle",
	DruidAttack = "DruidAttack",
	DruidAttackRight = "DruidAttackRight",
	DruidAttackLeft = "DruidAttackLeft",
	DruidAttackUp = "DruidAttackUp",
	DruidAttackDown = "DruidAttackDown",
	GeneralIdle = "GeneralIdle",
	GeneralAttack = "GeneralAttack",
	GeneralAttackRight = "GeneralAttackRight",
	GeneralAttackLeft = "GeneralAttackLeft",
	GeneralAttackUp = "GeneralAttackUp",
	GeneralAttackDown = "GeneralAttackDown",
	PaladinIdle = "PaladinIdle",
	PaladinAttack = "PaladinAttack",
	PaladinAttackRight = "PaladinAttackRight",
	PaladinAttackLeft = "PaladinAttackLeft",
	PaladinAttackUp = "PaladinAttackUp",
	PaladinAttackDown = "PaladinAttackDown",

	TileOutline = "TileOutline",
	TileOutlineAttack = "TileOutlineAttack",
	TileOutlineAttackable = "TileOutlineAttackable",
}


function createWalkAnimations() {
	return [
	  UnitTypes.Halberdier, UnitTypes.Hero, UnitTypes.Druid, UnitTypes.General, UnitTypes.Paladin
	].reduce(
	  (anims, unitType) => {
		anims[unitType] = [`${unitType}WalkUp`, `${unitType}WalkDown`, `${unitType}WalkLeft`, `${unitType}WalkRight`];
  
		return anims;
	  },
	  {} as Record<UnitTypes, [string, string, string, string]>,
	);
  }
  
export const WALK_ANIMATIONS = createWalkAnimations();

export enum Sprites {
	Settlement,
	SpawnSettlement,
	Halberdier,
	Hero,
	Druid,
	General,
	Paladin,
	Sword,
	GoldMine,
	WoodenWall,
	Boot,
	BootConfirm,
	BarBackground,
	HealthBar,
	HealthBarTick,
	HealthBarRed,
	HealthBarRedTick,
	Item1,
	Item2,
	Item3,
	Item4,
	Item5,
	Item6,
	Item7,
	Item8,
	Item9,
	Item10,
	Item11,
	Item12,
	Item13,
	Item14,
	Item15,
	Item16,
	Item17,
	Item18,
	Item19,
	Item20,
	Item21,
	Item22,
	Item23,
	Item24,
	Item25,
	Item26,
	Item27,
	Item28,
	Item29,
}

export const StructureTypeSprites: Record<number, Sprites> = {
	[StructureTypes.SpawnSettlement]: Sprites.SpawnSettlement,
	[StructureTypes.Settlement]: Sprites.Settlement,
	[StructureTypes.WoodenWall]: Sprites.WoodenWall,
	[StructureTypes.GoldMine]: Sprites.GoldMine,
}

export const StructureTypeAnimations: Record<number, Animations> = {
	[StructureTypes.GoldMine]: Animations.GoldMine,
	[StructureTypes.Settlement]: Animations.Settlement,
	[StructureTypes.SpawnSettlement]: Animations.SpawnSettlement,
};

export const UnitTypeSprites: Record<number, Sprites> = {
	[UnitTypes.Halberdier]: Sprites.Halberdier,
	[UnitTypes.Hero]: Sprites.Hero,
	[UnitTypes.Druid]: Sprites.Druid,
	[UnitTypes.General]: Sprites.General,
	[UnitTypes.Paladin]: Sprites.Paladin,
}

export const UnitTypeAnimations: Record<number, Animations> = {
	[UnitTypes.Halberdier]: Animations.HalberdierIdle,
	[UnitTypes.Hero]: Animations.HeroIdle,
	[UnitTypes.Druid]: Animations.DruidIdle,
	[UnitTypes.General]: Animations.GeneralIdle,
	[UnitTypes.Paladin]: Animations.PaladinIdle,
}
export const UnitTypeAttackAnimations: Record<number, Animations> = {
	[UnitTypes.Halberdier]: Animations.HalberdierAttack,
	[UnitTypes.Hero]: Animations.HeroAttack,
	[UnitTypes.Druid]: Animations.DruidAttack,
	[UnitTypes.General]: Animations.GeneralAttack,
	[UnitTypes.Paladin]: Animations.PaladinAttack,
};
export const UnitTypeAttackAnimationsDirection: Record<number, Record<"left" | "right" | "up" | "down", Animations>> = {
	[UnitTypes.Hero]: {
		left: Animations.HeroAttackLeft,
		right: Animations.HeroAttackRight,
		up: Animations.HeroAttackUp,
		down: Animations.HeroAttackDown,
	},
	[UnitTypes.Druid]: {
		left: Animations.DruidAttackLeft,
		right: Animations.DruidAttackRight,
		up: Animations.DruidAttackUp,
		down: Animations.DruidAttackDown,
	},
	[UnitTypes.General]: {
		left: Animations.GeneralAttackLeft,
		right: Animations.GeneralAttackRight,
		up: Animations.GeneralAttackUp,
		down: Animations.GeneralAttackDown,
	},
	[UnitTypes.Paladin]: {
		left: Animations.PaladinAttackLeft,
		right: Animations.PaladinAttackRight,
		up: Animations.PaladinAttackUp,
		down: Animations.PaladinAttackDown,
	},
}


export const UnitTypeAnimationAdjustments: Record<number, Record<string, Record<string, number>>> = {
	// original 64x64
	// [displayWidth, displayHeight, sizeAdjustment]
	[UnitTypes.Halberdier]: {
		["walk"]: {

		}, 
		["idle"]: {
			["displayWidth"] : TILE_WIDTH, 
			["displayHeight"] : TILE_HEIGHT,
			["sizeAdjust"] : 1,
			["originXAdjust"] : 1,
			["originYAdjust"] : 1,
		}, 
		["attack"]: {
			["originXAdjust"] : 0,
			["originYAdjust"] : 0,
		}, 
	},

	//[Hero-Reskin] [F] Generic Pants +Shiny Blue Shield
	// 65-0-120x110 [16..25]_attack
	// [UnitTypes.Hero]: {
	// 	["walk"]: {
	// 	}, 
	// 	["idle"]: {
	// 		["displayWidth"] : 1.5 * TILE_WIDTH, 
	// 		["displayHeight"] : 1.5 * TILE_HEIGHT,
	// 		["sizeAdjust"] : 1 / 1.5 ,
	// 		["originXAdjust"] : 0.07,
	// 		["originYAdjust"] : 0.02,
	// 	}, 
	// 	["attack"]: {
	// 		["originXAdjust"] : 0.1,
	// 		["originYAdjust"] : 0.35
	// 	}, 
	// },

	// tiny sword
	[UnitTypes.Hero]: {
		["walk"]: {
		}, 
		["idle"]: {
			["displayWidth"] : 1.75 * TILE_WIDTH, 
			["displayHeight"] : 1.75 * TILE_HEIGHT,
			["sizeAdjust"] : 1 ,
			["originXAdjust"] : -0.2,
			["originYAdjust"] : -0.35,
		}, 
		["attack"]: {
			["originXAdjust"] : 0,
			["originYAdjust"] : 0
		}, 
	},

	//[T2 Druid-Base] [M] Vanilla FE6-8 / Magic
	// 120-45-64x64 [0..6]_idle 
	// 110-0-88x113 [16..25]_attack
	// [UnitTypes.Druid]: {
	// 	["walk"]: {
	// 	}, 
	// 	["idle"]: {
	// 		["displayWidth"] : 1.2 * TILE_WIDTH, 
	// 		["displayHeight"] : 1.2 * TILE_HEIGHT,
	// 		["sizeAdjust"] : 1 / 2.2,
	// 		["originXAdjust"] : 0.15,
	// 		["originYAdjust"] : 0.05,
	// 	}, 
	// 	["attack"]: {
	// 		["originXAdjust"] : 0.1,
	// 		["originYAdjust"] : 0.42,
	// 	}, 
	// },

		// tiny sword
		[UnitTypes.Druid]: {
			["walk"]: {
			}, 
			["idle"]: {
				["displayWidth"] : 1.75 * TILE_WIDTH, 
				["displayHeight"] : 1.75 * TILE_HEIGHT,
				["sizeAdjust"] : 1 ,
				["originXAdjust"] : -0.2,
				["originYAdjust"] : -0.35,
			}, 
			["attack"]: {
				["originXAdjust"] : 0,
				["originYAdjust"] : 0
			}, 
		},

	// [General-Base] [U] Vanilla + Weapons / Lance (no chain)
	// 115-45-85-64 _idle
	// 56-8-168x128 _attack
	// [UnitTypes.General]: {
	// 	["walk"]: {
	// 	}, 
	// 	["idle"]: {
	// 		["displayWidth"] : 1.5 * TILE_WIDTH, 
	// 		["displayHeight"] : 1.1 * TILE_WIDTH,
	// 		["sizeAdjust"] : 1 / 1.5 ,
	// 		["originXAdjust"] : -0.02 ,
	// 		["originYAdjust"] : -0.28,
	// 	}, 
	// 	["attack"]: {
	// 		["originXAdjust"] : 0.15,
	// 		["originYAdjust"] : 0.35,
	// 	}, 
	// },

	// tiny sword
	[UnitTypes.General]: {
		["walk"]: {
		}, 
		["idle"]: {
			["displayWidth"] : 1.75 * TILE_WIDTH, 
			["displayHeight"] : 1.75 * TILE_HEIGHT,
			["sizeAdjust"] : 1 ,
			["originXAdjust"] : -0.2,
			["originYAdjust"] : -0.35,
		}, 
		["attack"]: {
			["originXAdjust"] : 0,
			["originYAdjust"] : 0
		}, 
	},

	// [Paladin-Base] [M] Vanilla +Weapons / Lance
	// 120-20-88-88 _idle
	// [UnitTypes.Paladin]: {
	// 	["walk"]: {
	// 	}, 
	// 	["idle"]: {
	// 		["displayWidth"] : 1.5 * TILE_WIDTH, 
	// 		["displayHeight"] : 1.5 * TILE_HEIGHT,
	// 		["sizeAdjust"] : 1 ,
	// 		["originXAdjust"] : -0.3 ,
	// 		["originYAdjust"] : -0.15,
	// 	}, 
	// 	["attack"]: {
	// 		["originXAdjust"] : 0.3,
	// 		["originYAdjust"] : 0.1,
	// 	}, 
	// },

	// tiny sword
	[UnitTypes.Paladin]: {
		["walk"]: {
		}, 
		["idle"]: {
			["displayWidth"] : 1.75 * TILE_WIDTH, 
			["displayHeight"] : 1.75 * TILE_HEIGHT,
			["sizeAdjust"] : 1 ,
			["originXAdjust"] : -0.2,
			["originYAdjust"] : -0.35,
		}, 
		["attack"]: {
			["originXAdjust"] : 0,
			["originYAdjust"] : 0
		}, 
	},
}

export function adjust(sprite: Phaser.GameObjects.Sprite, entityType: number, status: "idle" | "attack" | "walk") {
	if (status == "idle") {
		const animationSize = sprite.width * sprite.scaleX * UnitTypeAnimationAdjustments[entityType][status]["sizeAdjust"];
    	const newOrigin = (animationSize - TILE_WIDTH) / (2 * animationSize);
		sprite.setOrigin(newOrigin + UnitTypeAnimationAdjustments[entityType][status]["originXAdjust"], newOrigin + UnitTypeAnimationAdjustments[entityType][status]["originYAdjust"]);
		sprite.displayWidth = UnitTypeAnimationAdjustments[entityType][status]["displayWidth"]
		sprite.displayHeight = UnitTypeAnimationAdjustments[entityType][status]["displayHeight"]
	} 
	else if (status == "walk") {
		// const previousOrigin = [sprite.originX, sprite.originY]
		// const previousScale = [sprite.scaleX, sprite.scaleY]
		// sprite.setScale(1,1)
		// sprite.setOrigin(0, 0)
		sprite.setAlpha(0.5)
		return function () {
			// sprite.setOrigin(...previousOrigin)
			// sprite.setScale(previousScale[0], previousScale[1])
			sprite.setAlpha(1)
		}
	}else if (status == "attack"){
		const previousOrigin = [sprite.originX, sprite.originY]
		sprite.setOrigin(previousOrigin[0] + UnitTypeAnimationAdjustments[entityType][status]["originXAdjust"], previousOrigin[1] + UnitTypeAnimationAdjustments[entityType][status]["originYAdjust"]);
		return function () {
			sprite.setOrigin(...previousOrigin)
		}
	}
	return function () {}
}

export const UnitTypeDeathAnimations: Record<number, Animations> = {}
