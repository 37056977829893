import { tileCoordToPixelCoord } from "phaserx";
import { ZERO_VECTOR } from "phaserx";
import {
  Has,
  getComponentValueStrict,
  defineEnterSystem,
  runQuery,
  HasValue,
  getEntitiesWithValue,
  hasComponent,
  Entity,
} from "@latticexyz/recs";
import { isArray, sample } from "lodash";
import { TerrainTypes } from "../../../../Network";
import {  Assets } from "../../phaserConstants";
import { Tileset, WangSetKey, WangSets, TileAnimationKey } from "../../tilesets/overworld";
import { PhaserLayer } from "../../types";

export const terrainTypeToTile = {
  [TerrainTypes.Grass]: [
    Tileset.Grass1,
    Tileset.Grass1,
    Tileset.Grass1,
    Tileset.Grass1,
    Tileset.Grass2,
    Tileset.Grass3,
  ],
  [TerrainTypes.Mountain]: Tileset.Grass2,
  [TerrainTypes.Forest]: Tileset.Grass2,
} as { [key in TerrainTypes]: Tileset | Tileset[] };

const terrainTypesToForegroundTile = {
  [TerrainTypes.Grass]: [
    Tileset.Flower1,
    Tileset.Flower2,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],

  [TerrainTypes.Forest]: Tileset.Forest,
  [TerrainTypes.Mountain]: Tileset.Mountain,
} as { [key in TerrainTypes]: Tileset | (Tileset | null)[] };


const terrainTypeToAnimation = {
  // [TerrainTypes.Grass]: [TileAnimationKey.LongGrassBackground, null, null, null, null],
} as { [key in TerrainTypes]: TileAnimationKey | (TileAnimationKey | null)[] };

// const terrainTypeToForegroundAnimation = {} as { [key in TerrainTypes]: Animations };

const terrainTypeToWangSet = {} as { [key in TerrainTypes]: WangSetKey };

/**
 * The Map system handles rendering the phaser tilemap
 */
export function createMapSystem(layer: PhaserLayer) {
	const {
		world,
		parentLayers: {
      network: {
        components: { Position, TerrainType, MatchReady },
        network: { matchEntity },
      },
      headless: {
        components: { InCurrentMatch },
      },
    },
    scenes: {
      Main: {
        config,
        maps: { Main },
      },
    },
	} = layer

  // TODO: commented till we fix the uploader to bundle untraversable and entity type together
  // defineEnterSystem(world, [Has(Position), Not(TerrainType)], (update) => {
  // const coord = getComponentValueStrict(Position, update.entity);
  // Main.putTileAt(coord, Tileset.Plain);
  // });
  //
  const WANG_OFFSET = [
    { x: 0, y: -1 },
    { x: 1, y: -1 },
    { x: 1, y: 0 },
    { x: 1, y: 1 },
    { x: 0, y: 1 },
    { x: -1, y: 1 },
    { x: -1, y: 0 },
    { x: -1, y: -1 },
  ];

  function calculateWangId(coord: Coord, entityType: TerrainTypes) {
    const bits = [];
    for (const offset of WANG_OFFSET) {
      const checkCoord = addCoords(coord, offset);
      const entities = runQuery([HasValue(Position, checkCoord), HasValue(TerrainType, { value: entityType })]);
      if (entities.size > 0) {
        bits.push(1);
      } else {
        bits.push(0);
      }
    }

    // turn the bitstring into a decimal number (with MSB on the right!)
    // ignore "corner" bits if their neighbors are not set
    // corner bits are bits [1,3,5,7]
    // 7 | 0 | 1
    // 6 | x | 2
    // 5 | 4 | 3
    return bits.reduce((acc, b, i, arr) => {
      if (i % 2 === 0) {
        return acc + Math.pow(2, i) * b;
      } else {
        const before = (i - 1) % 8;
        const after = (i + 1) % 8;
        if (arr[before] && arr[after]) {
          return acc + Math.pow(2, i) * b;
        } else {
          return acc;
        }
      }
    });
  }

  function getWangSet(entityType: TerrainTypes) {
    const wangSetKey = terrainTypeToWangSet[entityType];
    if (!wangSetKey) return;
    return WangSets[wangSetKey];
  }

  function drawWangSetAtCoord(coord: Coord, entityType: TerrainTypes) {
    const wangSet = getWangSet(entityType);
    if (!wangSet) return;
    // redraw itself then all neighbors
    for (const offset of [ZERO_VECTOR, ...WANG_OFFSET]) {
      // is this tile an entity of type entityType?
      const coordToRedraw = addCoords(coord, offset);
      const entities = runQuery([HasValue(Position, coordToRedraw), HasValue(TerrainType, { value: entityType })]);
      if (entities.size === 0) continue;
      const wangId = calculateWangId(coordToRedraw, entityType);
      if (wangSet[wangId] == null) continue;
      Main.putTileAt(coordToRedraw, wangSet[wangId], "Foreground");
    }
  }
	defineEnterSystem(world, [Has(MatchReady)], ({ entity }) => {
		if (entity != matchEntity) return;
		defineEnterSystem(
      world,
      [Has(InCurrentMatch), Has(Position), Has(TerrainType)],
			(update) => {
				const coord = getComponentValueStrict(Position, update.entity);
        const { tileWidth, tileHeight } = Main;
        const type = getComponentValueStrict(TerrainType, update.entity);
        
        const tile = terrainTypeToTile[type.value as TerrainTypes];
        const foregroundTile = terrainTypesToForegroundTile[type.value as TerrainTypes];
        let tint: number | undefined;
        const backgroundTile = isArray(tile) ? sample(tile) : tile;
        if (!backgroundTile) return;
        Main.putTileAt(coord, backgroundTile, undefined, tint);
				
        if (foregroundTile) {
          const t = isArray(foregroundTile) ? sample(foregroundTile) : foregroundTile;
          if (!t) return;
          Main.putTileAt(coord, t, "Foreground");
        }
        drawWangSetAtCoord(coord, type.value);
			},
      { runOnInit: true },
		)
	})
}
