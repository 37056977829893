import { Entity, getComponentValue, hasComponent, removeComponent, setComponent } from "@latticexyz/recs";
import { PhaserLayer } from "../..";
import {
  Animations,
  TILE_HEIGHT,
  TILE_WIDTH,
  UnitTypeAnimations,
  UnitTypeAttackAnimations,
  UnitTypeDeathAnimations,
  UnitTypeAttackAnimationsDirection,
  adjust
} from "../../phaserConstants";
import { encodeMatchEntity } from "../../../../../encodeMatchEntity";
import { tileCoordToPixelCoord } from "phaserx";
import { RenderDepth } from "../../types";

export function createFightSystem(layer: PhaserLayer) {
	const {
		parentLayers: {
			network: {
				network: { matchEntity },
				components: { UnitType, StructureType, FightState },
				utils: { isOwnedByCurrentPlayer },
			},
			local: {
				components: { LocalPosition, Selected },
				api: {
					getOwnerColor,
					systemDecoders: { onFightState },
				},
			},
			headless: {
				components: { PreviousOwner, OnCooldown },
				api: {
					// fightState: { canRetaliate },
				},
			},
		},
		scenes: {
			Main: { phaserScene },
		},
		api: { playTintedAnimation, depthFromPosition },
		globalObjectPool,
	} = layer;

	function playAttackAnimation(
    entity: Entity,
    entityOwner: Entity,
    direction: "left" | "right" | "up" | "down",
    {
      onStart,
      onContact,
      onComplete,
    }: {
      onStart?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onContact?: (sprite?: Phaser.GameObjects.Sprite) => void;
      onComplete?: (sprite?: Phaser.GameObjects.Sprite) => void;
    },
  ) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      if (onStart) onStart();
      if (onContact) onContact();
      if (onComplete) onComplete();
      return;
    }

    /**
     * If for some reason the attack animation gets interrupted,
     * make sure we clean up state. This seems to sometimes happen
     * when the tab is unfocused and leaves units in incorrect states.
     */
    let ranStart = false;
    let ranContact = false;
    let ranComplete = false;
    setTimeout(() => {
      if (onStart && !ranStart) onStart();
      if (onContact && !ranContact) onContact();
      if (onComplete && !ranComplete) onComplete();
    }, 5_000);

    const attackAnimation = UnitTypeAttackAnimationsDirection[unitType][direction];

    const idleAnimation = UnitTypeAnimations[unitType];

    const sprite = globalObjectPool.get(entity, "Sprite");
    const ownerColor = getOwnerColor(entityOwner, matchEntity);
    if (!attackAnimation) {
      if (onComplete) onComplete(sprite);
      return;
    }

    const revertOrigin = adjust(sprite, unitType, "attack");
    const tintedAnimation = playTintedAnimation(entity, attackAnimation, ownerColor.name);
    
    let started = false;
    const onAttackUpdate = (anim: Phaser.Animations.Animation, frame: Phaser.Animations.AnimationFrame) => {
      if (anim.key !== tintedAnimation) return;

      if (!started && onStart) {
        onStart(sprite);
        ranStart = true;
        started = true;
      }

      if (frame.progress >= 1) {
        playTintedAnimation(entity, idleAnimation, ownerColor.name);
      }
      if (onContact && frame.nextFrame?.isLast) {
        onContact(sprite);
        ranContact = true;
      }
      if (onComplete && frame.progress >= 1) {
        revertOrigin();
        onComplete(sprite);
        ranComplete = true;
        sprite.removeListener("animationupdate", onAttackUpdate);
      }
    };

    sprite.on(`animationupdate`, onAttackUpdate);
  }

	onFightState(
    ({
      attacker,
      attackerDied,
      attackerDamageReceived,
      defenderDamageReceived,
      defender,
      defenderDied,
      ranged,
      defenderCaptured,
    }) => {
      if (!matchEntity) return;

      if (attackerDied && hasComponent(Selected, attacker)) removeComponent(Selected, attacker);
      if (defenderDied && hasComponent(Selected, defender)) removeComponent(Selected, defender);

			// TODO: 
      // if (defenderCaptured) clearIncomingDamage(attacker, defender);

      const attackerPosition = getComponentValue(LocalPosition, attacker);
      if (!attackerPosition) return;

      const defenderPosition = getComponentValue(LocalPosition, defender);
      if (!defenderPosition) return;

      const attackerPreviousOwner = getComponentValue(PreviousOwner, attacker);
      const defenderPreviousOwner = getComponentValue(PreviousOwner, defender);

      const attackerOwner = attackerPreviousOwner
        ? encodeMatchEntity(matchEntity, attackerPreviousOwner.value)
        : ("0" as Entity);
      const defenderOwner = defenderPreviousOwner
        ? encodeMatchEntity(matchEntity, defenderPreviousOwner.value)
        : ("0" as Entity);

      const defenderIsStructure = getComponentValue(StructureType, defender);
      /**
       * flipAttacker/flipDefender depends on original attack-animation direction 
       * then animations of all units should align same direction
       */
      const flipAttacker = !(defenderPosition.x <= attackerPosition.x);
      const flipDefender = !(attackerPosition.x <= defenderPosition.x);

      const attackerTookDamage = attackerDamageReceived > 0;
      const defenderTookDamage = defenderDamageReceived > 0;

      const attackerHealth = getComponentValue(FightState, attacker)?.health || 0;
      const defenderHealth = getComponentValue(FightState, defender)?.health || 0;
      let direction = ""
      if (defenderPosition.x < attackerPosition.x) direction = "left"
      else if (defenderPosition.x > attackerPosition.x) direction = "right"
      else if (defenderPosition.y > attackerPosition.y) direction = "down"
      else direction = "up"
      playAttackAnimation(attacker, attackerOwner, direction, {
        onStart: (sprite) => {
          // if (sprite) sprite.flipX = flipAttacker;
        },
        onContact: (sprite) => {
					//TODO: 
          // clearIncomingDamage(attacker, defender);
          // clearIncomingDamage(defender, attacker);

          // if (attackerDied) {
          //   setComponent(LocalHealth, attacker, { value: 0 });
          // } else if (attackerTookDamage) {
          //   setComponent(LocalHealth, attacker, { value: attackerHealth });
          // }

          // if (attackerDied || attackerTookDamage) {
          //   flashWhite(attacker);
          // }

          // if (defenderDied) {
          //   setComponent(LocalHealth, defender, { value: 0 });
          // } else if (defenderTookDamage) {
          //   setComponent(LocalHealth, defender, { value: defenderHealth });
          // }

          // if (defenderDied || defenderTookDamage) {
          //   if (!defenderIsStructure) {
          //     flashWhite(defender);
          //   }
          // }

          // if (defenderCaptured) {
          //   setComponent(Capturer, defender, { value: attacker });
          // }
        },
        onComplete: (sprite) => {
          // if (sprite && flipAttacker) sprite.flipX = false;
					if (attackerDied) {
						removeComponent(LocalPosition, attacker);
					}
					if (defenderDied) {
						removeComponent(LocalPosition, defender);
					}
					// TODO: 

          // if (attackerDied) {
          //   playDeathAnimation(attacker, attackerOwner, () => {
          //     removeComponent(LocalHealth, attacker);
          //     removeComponent(LocalPosition, attacker);
          //   });
          // }

          // if (defenderDied) {
          //   playDeathAnimation(defender, defenderOwner, () => {
          //     removeComponent(LocalHealth, defender);
          //     removeComponent(LocalPosition, defender);
          //   });

          //   const goldOnKill = getComponentValue(GoldOnKill, defender)?.value;
          //   if (isOwnedByCurrentPlayer(attacker) && goldOnKill) {
          //     playGoldOnKillAnimation(defenderPosition, goldOnKill);
          //   }
          // }
        },
      });
			// TODO: unimplemented, defender fight back
      // if (!ranged && canRetaliate(layer.parentLayers.network, defender))
      //   playAttackAnimation(defender, defenderOwner, {
      //     onStart: (sprite) => {
      //       if (sprite) sprite.flipX = flipDefender;
      //     },
      //     onComplete: (sprite) => {
      //       if (sprite && flipDefender) sprite.flipX = false;
      //     },
      //   });
    },
  );
}
