import { defineSystem, getComponentValueStrict, Has, UpdateType } from "@latticexyz/recs";
import { Animations, UnitTypeAnimationAdjustments, adjust } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";
import { tileCoordToPixelCoord } from "phaserx";

/**
 * The Appearance system handles setting textures of phaser game objects based on their Appearance component
 */
export function createSpriteAnimationSystem(layer: PhaserLayer) {
  const {
    world,
    components: { SpriteAnimation, HueTint},
    api: { playTintedAnimation },
    globalObjectPool,
    scenes: {
      Main: {
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    parentLayers: {
      local: {
        components: { LocalPosition },
      },
    },
  } = layer;

  defineSystem(world, [Has(SpriteAnimation), Has(HueTint), Has(LocalPosition)], ({ entity, type, component }) => {
    if (![UpdateType.Enter, UpdateType.Update].includes(type)) return;
    if (type === UpdateType.Update && component.id === LocalPosition.id) return;

    const sprite = globalObjectPool.get(entity, "Sprite");

    const animation = getComponentValueStrict(SpriteAnimation, entity);
    const hueTint = getComponentValueStrict(HueTint, entity).value;
    playTintedAnimation(entity, animation.value as Animations, hueTint);
    sprite.setScale(1,1)
    adjust(sprite, animation.entityType, "idle");
    sprite.setDepth(RenderDepth.AlwaysOnTop);
  });
}
