import {
	defineRxSystem,
	defineSystem,
	Entity,
	getComponentValue,
	getComponentValueStrict,
	Has,
	removeComponent,
	runQuery,
	setComponent,
	UpdateType,
} from "@latticexyz/recs";
import { HeadlessLayer } from "../../types";
import { decodeMatchEntity } from "../../../../decodeMatchEntity";

export function createCooldownSystem(layer: HeadlessLayer) {
	const {
		world,
		components: { OnCooldown, InCurrentMatch },
		parentLayers: {
			network: {
				network: {
					components: { MatchTurn, UnitLastTurn, UnitType, Position },
					matchEntity,
				},
				utils: { isOwnedByCurrentPlayer },
			},
		},
	} = layer;
	defineSystem(world, [Has(MatchTurn)], ({ entity, type }) => {
		if (entity != matchEntity) return;
		const currentTurn = getComponentValueStrict(MatchTurn, entity);
		
		if (type === UpdateType.Enter) {
			const entities = [...runQuery([Has(UnitType), Has(Position), Has(InCurrentMatch)])]
			const ownedEntities: Entity[] = []
			for (let i=0; i< entities.length; i++) {
				if (isOwnedByCurrentPlayer(entities[i])) {
					ownedEntities.push(entities[i])
				}
			}

			for (const entity of ownedEntities) {
				if (!getComponentValue(UnitLastTurn, entity)) {
					setComponent(UnitLastTurn, entity, { value: 0 });
				}
			}
		} else if (type === UpdateType.Update) {
			const ownedEntities = [...runQuery([Has(UnitLastTurn), Has(InCurrentMatch)])]
			for (const entity of ownedEntities) {
				const lastTurn = getComponentValue(UnitLastTurn, entity);
				if (!!lastTurn && lastTurn.value == currentTurn.value) {
					setComponent(OnCooldown, entity, { value: true });
				} else {
					removeComponent(OnCooldown, entity);
				}
			}
		}
	});
	defineSystem(world, [Has(UnitLastTurn), Has(InCurrentMatch)], ({ entity }) => {
		if (decodeMatchEntity(entity).matchEntity != matchEntity) return;
		const lastTurn = getComponentValue(UnitLastTurn, entity);
		const currentTurn = getComponentValue(MatchTurn, matchEntity);
		if (!lastTurn || !currentTurn) {
			return
		}
		if (lastTurn.value == currentTurn.value) {
			setComponent(OnCooldown, entity, { value: true });
		} else {
			removeComponent(OnCooldown, entity);
		}
	});
}  
