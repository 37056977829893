export default {
  enums: {
    ItemTypes: [
      "Unknown",
      "Item1", "Item2", "Item3", "Item4", "Item5", "Item6", "Item7", "Item8", "Item9", "Item10",
      "Item11", "Item12", "Item13", "Item14", "Item15", "Item16", "Item17", "Item18", "Item19", "Item20",
      "Item21", "Item22", "Item23", "Item24", "Item25", "Item26", "Item27", "Item28", "Item29"
    ]
  },
  components: {
    Items: {
      key: [],
      schema: {
        value: "bytes32[]",
      },
    },
    UnitItems: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "bytes32[]",
      },
    },
    NFTItems: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "bytes32[]",
      },
    },
    ItemType: {
      type: "offchainTable",
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "ItemTypes",
      },
    },
    StatHp: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    StatStr: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    StatMov: {
      key: ["matchEntity", "entity"],
      schema: {
        matchEntity: "bytes32",
        entity: "bytes32",
        value: "uint32",
      },
    },
    // StatSkill: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatSpd: {
    //   key: ["matchEntity", "entity"], 
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatLuck: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatDef: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatRes: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatCon: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
    // StatAid: {
    //   key: ["matchEntity", "entity"],
    //   schema: {
    //     matchEntity: "bytes32",
    //     entity: "bytes32",
    //     value: "uint32",
    //   },
    // },
  }
}
