import { useStore } from "./useStore";

export const useAmalgema = () => {
  const { networkLayer, externalWalletClient, faucetClient, externalWorldContract, writes,headlessLayer, localLayer, phaserLayer } = useStore();
  
  if (networkLayer === null) {
    throw new Error("Network layer not initialized");
  }

  return { ...networkLayer, externalWalletClient, faucetClient, externalWorldContract, writes };
};
