import { Component, ComponentUpdate, defineSystem, Entity, getComponentValue, getComponentValueStrict, Has, hasComponent, HasValue, Not, runQuery, UpdateType, Type, setComponent } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";
import { Subscription } from "rxjs";
import { compact, curry, zip } from "lodash";
import { Coord } from "@latticexyz/utils";
import { aStar } from "../../../../../utils/pathfinding";
import { WorldCoord } from "phaserx/src/types";


/**
 * Input: 
 *      - PotentialPath: set by [Local]PotentialPathSystem <-- [Local]SelectionSystem
 *      - LocalPosition: set by [Local]SyncSystem
 *      - NextPosition: set by [Phaser]regiserClicks
 * Output:
 *      - Draw Arrows along with hovered position
 * Explanation: 
 *      - Active only before destination commited. Reflect by !NextPosition
 *      - NOT Apply to Non-Owned unit
 * @param layer 
 */
export function drawMovementArrows(layer: PhaserLayer){
	const {
        world,
				api: {
          arrowPainter: { paintArrowAlongPath },
        },
        components: { HoverHighlight, PreviousMovableHightlight },
        parentLayers: {
          network: {
            components: { Position, Untraversable },
            utils: { isOwnedByCurrentPlayer, getOwningPlayer },
          },
          local: {
            components: { PotentialPath, LocalPosition },
          },
          headless: {
            api: { getMovementDifficulty, isUntraversable },
            components: { NextPosition, InCurrentMatch, OnCooldown },
          },
        },
        scenes: {
          Main: { phaserScene },
        },
      } = layer;

  defineSystem(world, [Has(PotentialPath), Has(LocalPosition), Not(NextPosition)], ({ entity, type }) => {
    initializePathObjects(entity);

    /**
     * Clear inner contents while maintain singleton phaser's instance regard to each entity
     */
    const pathObjects = entityToPathObjects[entity];
    pathObjects.pathLineDrawSub?.unsubscribe();
    entityToPathObjects[entity].pathLineDrawSub = undefined;
    pathObjects.lines?.clear(true);

    /**
     * Not apply to NON-Owned units
     * TODO: WTF is these 2 diff?
     */
    if (!isOwnedByCurrentPlayer(entity)) return;
    if (type === UpdateType.Exit) return;
    
    const owningPlayer = getOwningPlayer(entity);
    if (!owningPlayer) return;

    const potentialPath = getComponentValue(PotentialPath, entity);
    if (!potentialPath) return;

    entityToPathObjects[entity].lines = phaserScene.add.group();
    entityToPathObjects[entity].pathLineDrawSub = HoverHighlight.update$.subscribe((update) => {
				// entityToPathObjects[entity].lines?.clear(true);
        const hoverHighlight = getComponentValue(HoverHighlight, update.entity);
        if (!hoverHighlight) return;

        /**
         * Hovering to a blocked coord || Cooldown -> no-op
         */
        const blockingEntities = runQuery([Has(InCurrentMatch), HasValue(Position, hoverHighlight), Has(Untraversable)])
        if (blockingEntities.size > 0) return;
        if (!isOwnedByCurrentPlayer(entity) || hasComponent(OnCooldown, entity)) return;

        /**
         * Start drawing
         */
        const unitPosition = getComponentValueStrict(LocalPosition, entity);
      	const hoverCoord = { x: hoverHighlight.x, y: hoverHighlight.y };

				const potentialPathCoords = compact(
					zip(potentialPath.x, potentialPath.y).map(([x, y]) => {
						if (x === undefined || y === undefined) return null;
						return { x, y };
					}),
				);

				/**
				 * Checking if hovering inside movement area
				 */
				const hoveringOverPotentialPath = potentialPathCoords.find(
					({ x, y }) => x === hoverHighlight.x && y === hoverHighlight.y,
				);

				if (hoveringOverPotentialPath 
					// && hoveredAttackableEntities.length === 0 // TODO: currently disabled, cause FightState not impled yet
				) {
          entityToPathObjects[entity].lines?.clear(true);
          setComponent(PreviousMovableHightlight, entity, hoverHighlight);
					pathObjects.lines = draw(unitPosition, hoverCoord, owningPlayer);
					entityToPathObjects[entity].lines?.setAlpha(1)
				}
    })
  })

  const entityToPathObjects: Record<
    Entity,
    {
        lines: Phaser.GameObjects.Group | undefined, 
        linesNext: Phaser.GameObjects.Group | undefined;
        pathLineDrawSub: Subscription | undefined;
    }
  > = {}
  function initializePathObjects(entity: Entity) {
    if (!!entityToPathObjects[entity]) { return }
    entityToPathObjects[entity] = {
        lines: phaserScene.add.group(),
        linesNext: phaserScene.add.group(),
        pathLineDrawSub: undefined,
    }
  }

	/**
	 * Draw arrows
	 * LocalPosition fullfil interface Component<{x: number, y:number}>
	 * TODO: Current not work when hovering in OWNED-units
	 */
	function draw(from: Coord, to: Coord, player: Entity){
		// const getMovementDifficulty = (
		// 	positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
		// 	targetPosition: WorldCoord,
		// ) => { return 0}

		// const isUntraversable = 
		// (
		// 	positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
		// 	playerEntity: Entity,
		// 	isFinalPosition: boolean,
		// 	position: WorldCoord,
		// ) => { return false }
		const unitPath = aStar(
			from, to, 100_000,
			curry(getMovementDifficulty)(LocalPosition),
			curry(isUntraversable)(LocalPosition, player),
		)
		unitPath.unshift(from);
		return paintArrowAlongPath("Move", unitPath);
	}
}
