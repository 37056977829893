/** @jsxImportSource phaser-jsx */

import { render } from 'phaser-jsx';
import { createRef, Text, useScene } from 'phaser-jsx';
interface Props {
	text: string;
	onEnd?: () => void;
}
		
function Guideline(props: Props) {
	const scene = useScene();
	const ref = createRef<Phaser.GameObjects.Text>();
	let index = 0;

	setTimeout(()=>{
		scene.time.addEvent({
			callback() {
				ref.current!.alpha = 0.95;

				if (props.text[index] == "|") {
					ref.current!.text += "\n";
				}else if (props.text[index] !== ".") {
					ref.current!.text += props.text[index];
				} 
				index++;

				if (index >= props.text.length) {
					index = 0; ref.current!.text = "";
				}
			},

			delay: 100,
			loop: true,
		});
	}, 1000)


	return (
		<Text
			x={16}
			y={16}
			style={{
				color: '#FFD700', // Gold color
				fontFamily: '"VT323", monospace', // Retro game font
				fontSize: '20px',
				backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black background
				// @ts-expect-error padding
				padding: { x: 25, y: 15 },
				stroke: '#000000', // Black stroke
				strokeThickness: 2,
				shadow: { color: '#000000', blur: 15, offsetX: 2, offsetY: 2 }, // Text shadow
				wordWrap: { width: 800 }, // Wrap text at 800 pixels
				lineSpacing: 10, // Add some line spacing
			}}
			alpha={0}
			scrollFactorX={0}
			scrollFactorY={0}
			depth={10000}
			ref={ref}
		/>
	);
}

export default function playGuideline(scene: Phaser.Scene) {
	render(
		<Guideline
			text="Warmup chapter: Defeat 1 enemy..................................|Use the mouse to control your heroes (the blue ones)..................................|Click on your base village to end your turn................................."
			onEnd={() => {}}
		/>,
		scene,
	);
}

