import {
	Has,
	getComponentValue,
	defineSystem,
	UpdateType,
	isComponentUpdate,
	getComponentValueStrict,
	Not,
	defineExitSystem,
  defineQuery,
  defineRxSystem,
} from "@latticexyz/recs";
import { Area, awaitStreamValue, sleep } from "@latticexyz/utils";
import { FAST_MOVE_SPEED, UNIT_OFFSET } from "../../../../Local/constants";
import { tileCoordToPixelCoord } from "phaserx";
import { PhaserLayer } from "../../types";
import { manhattan } from "../../../../../utils/distance";
import { Animations, WALK_ANIMATIONS, adjust } from "../../phaserConstants";
import { UnitTypes } from "../../../../Network";
import { concatMap, merge } from "rxjs";

/**
 * The LocalPosition system handles moving phaser game objects to the WorldCoord specified in their LocalPosition component.
 */
export function createLocalPositionSystem(layer: PhaserLayer) {
	const {
    world,
    components: { Appearance },
    parentLayers: {
      network: {
        components: { UnitType, Movable },
      },
      local: {
        components: { LocalPosition },
      },
    },
    scenes: {
      Main: {
        phaserScene,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    globalObjectPool,
    api: { playAnimationWithOwnerColor },
  } = layer;


  // [up, down, left, right]
  function calculateDirectionIndex(oldPos: WorldCoord, newPos: WorldCoord) {
    if (newPos.y < oldPos.y) {
      return 0;
    } else if (newPos.y > oldPos.y) {
      return 1;
    } else if (newPos.x < oldPos.x) {
      return 2;
    } else {
      return 3;
    }
  }

	
  /**
   * Set Position of NON-Unit on map (Ex: Structure)
   */
	defineSystem(world, [Has(LocalPosition), Has(Appearance), Not(UnitType)], ({ entity, type }) => {
    // Not(UnitType) Ex: Structure

    if (type === UpdateType.Enter) {
      const sprite = globalObjectPool.get(entity, "Sprite");
      const position = getComponentValue(LocalPosition, entity);
      if (!position) return;

      const pixel = tileCoordToPixelCoord(position, tileWidth, tileHeight);
      sprite.setPosition(pixel.x, pixel.y);
    }
  });


  /**
   * Set Position of Unit on map
   */
  defineSystem(world, [Has(LocalPosition), Has(Appearance), Has(UnitType)], ({ entity, type }) => {
    if (type === UpdateType.Enter) {
      const sprite = globalObjectPool.get(entity, "Sprite");
      const position = getComponentValue(LocalPosition, entity);
      if (!position) return;

      const pixel = tileCoordToPixelCoord(position, tileWidth, tileHeight);
      sprite.setPosition(pixel.x, pixel.y - UNIT_OFFSET);
    }
  });

  /**
   * Remove the entity from globalObjectPool when it exits
   */
  defineExitSystem(world, [Has(LocalPosition), Has(Appearance)], ({ entity }) => {
    globalObjectPool.remove(entity);
  });


  /**
   * TODO: WTF is this for?
   */
  // defineSystem(world, [Has(LocalPosition), Has(Appearance), Not(Movable)], (update) => {
  //   if (!isComponentUpdate(update, LocalPosition)) return;
  //   const [newPosition] = update.value;
  //   if (!newPosition) return;

  //   const pixelCoord = tileCoordToPixelCoord(newPosition, tileWidth, tileHeight);
  //   const sprite = globalObjectPool.get(update.entity, "Sprite");
  //   sprite.setPosition(pixelCoord.x, pixelCoord.y);
  // });


  /**
   * TODO: come back here after impl contract's move and move tx confirmation 
   * Move the unit to the new position
   * Ex: from 5 to 7, then trigger 2 times: 5->6 and 6->7
   * Trigged when tx commited and start moving animation
   */

  defineSystem(world, [Has(LocalPosition), Has(UnitType), Has(Appearance)], (update) => {
    if (update.type === UpdateType.Exit) {
      return globalObjectPool.remove(update.entity);
    }
    if (!isComponentUpdate(update, LocalPosition)) return;
    const [newPosition, oldPosition] = update.value;

    if (!newPosition || !oldPosition) return;

    const sprite = globalObjectPool.get(update.entity, "Sprite");
    const moveSpeed = FAST_MOVE_SPEED;
    if (update.type === UpdateType.Update && update.component.id === LocalPosition.id) {
      const [newPosition, oldPosition] = update.value;
      if (!newPosition || !oldPosition) return;

      
      const unitType = getComponentValueStrict(UnitType, update.entity).value;
      const isAdjacentMove = manhattan(newPosition, oldPosition) === 1;
      const walkAnimations = WALK_ANIMATIONS[unitType as UnitTypes];

      const pixelPosition = tileCoordToPixelCoord(newPosition, tileWidth, tileHeight);
      
      if (isAdjacentMove && walkAnimations) {
        const directionIndex = calculateDirectionIndex(oldPosition, newPosition);
        const anim = walkAnimations[directionIndex];
        const currentAnim = sprite.anims.currentAnim;
        if (anim !== currentAnim?.key) {
          /**
           * @notice walk-animation predefined in animationConfig. 
           * Ex: entity -> Halberdier -> HalberdierWalkUp -> animationConfig -> sprites-sheet
           */
          const revertOrigin = adjust(sprite, unitType, "walk");
          playAnimationWithOwnerColor(update.entity, anim as Animations);
          setTimeout(() => {
            revertOrigin()
          }, moveSpeed)
        }
      }
      
      phaserScene.add.tween({
        targets: sprite,
        duration: moveSpeed,
        x: pixelPosition.x,
        y: pixelPosition.y - UNIT_OFFSET,
        ease: Phaser.Math.Easing.Linear,
      });
    }
  });
}
