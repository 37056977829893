import { useEffect, useMemo, useState } from "react";
import { useStore } from "../useStore";
import { useNetworkLayer } from "./useNetworkLayer";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { WagmiProviderProps } from 'wagmi';
import { WagmiProvider, http } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { getNetworkConfig } from "../mud/getBrowserNetworkConfig";
import { ExternalWalletProvider } from "./ExternalWalletProvider";
import "@rainbow-me/rainbowkit/styles.css";
import { useBurnerBalance } from "./amalgema-ui/hooks/useBalance";
import { useAmalgema } from "../useAmalgema";
import { parseEther } from "viem";

export const queryClient = new QueryClient();

export type Props = {
  children: React.ReactNode;
};

export function Providers(props: Props) {
  const networkConfig = useMemo(() => getNetworkConfig(), []);
  const wagmiConfig = useMemo(
    () =>
      getDefaultConfig({
        appName: "fullyonchainstudio",
        projectId: "e4a3d29d0b3a1652a8df8a36dc03bf3d",
        chains: [networkConfig.chain],
        transports: {
          [networkConfig.chain.id]: http(),
        },
      }),
    [networkConfig],
  );

  const networkLayer = useNetworkLayer(networkConfig);
  useEffect(() => {
    if (networkLayer) {
      useStore.setState({ networkLayer });
    }
  }, [networkLayer]);

  const { networkLayer: networkLayerStored } = useStore();
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          {!!networkLayerStored && <NetworkLayerReady networkConfig={networkConfig} props={props} />}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export type Propsss = {
  props: Props;
  networkConfig: ReturnType<typeof getNetworkConfig>;
};

const NetworkLayerReady = function({ props, networkConfig }: Propsss){
  const burnerBalance = useBurnerBalance();
  const { faucetClient, externalWalletClient } = useAmalgema();
  const [ready, setReady] = useState(false);
  if (burnerBalance.danger){
    faucetClient.sendTransaction({
      chain: faucetClient.chain,
      account: faucetClient.account,
      to: externalWalletClient.account.address,
      value: parseEther("0.001"),
    }).then(() => {
      setReady(true)
    });
  }else if (!ready) {
    setReady(true)
  }
  if (!ready) return null
  return <ExternalWalletProvider networkConfig={networkConfig}>{props.children}</ExternalWalletProvider> 
}
