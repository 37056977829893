import { createBrowserRouter } from "react-router-dom";
import { FireEmblem } from "./app/FireEmblem";
import { Amalgema } from "./app/Amalgema";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Amalgema />,
  },
  {
    path: "/match",
    element: <FireEmblem />,
  },
]);
  