
import { PhaserLayer } from "../../types";
import { drawMovementArea  } from "./drawMovementArea";
import { drawMovementArrows  } from "./drawMovementArrows";

export function createDrawPotentialPathSystem(layer: PhaserLayer) {
	drawMovementArea(layer)
  drawMovementArrows(layer)
	
	/**
	 * draw movement arrow to next position ghost
	 * position ghost is the when user commit to the NextPosition but tx not mined yet or when prepare attacking some target
   * TODO: check logic to avoid moving immediateley, waitting for confirmation action, the purpose is allow players to consider their movement action 
	 */
  // defineSystem(world, [Has(LocalPosition), Has(NextPosition)], ({ entity, type }) => {
  // });
}
