import { LocalLayer } from "../../Local";
import {
  createMapSystem,
  createInputSystem,
  createDrawHighlightCoordSystem,
  createSelectionSystem,
  createSetVisualsSystem,
  createAppearanceSystem,
  createLocalPositionSystem,
  createSpriteAnimationSystem,
  createDrawPotentialPathSystem,
  createDrawNextPositionSystem,
  createHueTintSystem,
  createDrawAttackableEntitiesSystem,
  createFightSystem,
  createMenuSystem,
  createConstrainCameraSystem,
  createDrawEntityHeader
} from "./systems";
import { createCalculateFightResultSystem } from "./systems/CalculateFightResultSystem";
import { createTintOnCooldownSystem } from "./systems/TintOnCooldownSystem";
import {
    ComponentUpdate,
    defineSystem,
    Entity,
    getComponentValue,
    getComponentValueStrict,
    namespaceWorld,
    QueryFragment,
    removeComponent,
    setComponent,
    UpdateType,
  } from "@latticexyz/recs";
  import { singletonEntity } from "@latticexyz/store-sync/recs";
import { highlightCoord } from "./api";
import { curry } from "lodash";
import { Coord, ValueOf } from "@latticexyz/utils";
import { createPhaserEngine, tileCoordToPixelCoord } from "phaserx";
import { createPhaserComponents } from "./components";
import { gameConfig } from "./gameConfig";
import { observable } from "mobx";
import { Scenes } from "./phaserConstants";
import { Animations, Sprites } from "./phaserConstants";
import PLAYER_COLORS from "../../Local/player-colors.json";
import { createArrowPainter } from "./createArrowPainter";
import { RenderDepth } from "./types";
import { WorldCoord } from "phaserx/src/types";

type PhaserEngineConfig = Parameters<typeof createPhaserEngine>[0];

/**
 * The Phaser layer extends the Local layer.
 * Its purpose is to render the state of parent layers to a Phaser world.
 */
export async function createPhaserLayer(local: LocalLayer, phaserConfig: PhaserEngineConfig = gameConfig) {
  const {
    api: { selectEntity, getOwnerColor },
    components: { LocalPosition },
    parentLayers: {
      network: {
        network: { matchEntity },
      },
    }
  } = local;
	const world = namespaceWorld(local.parentLayers.network.network.world, "phaser");   
  const components = createPhaserComponents(world);
  // Create phaser engine
  const { game, scenes, dispose: disposePhaser } = await createPhaserEngine(phaserConfig);
  world.registerDisposer(disposePhaser);

  game.scene.start(Scenes.UI);
  // Disable zoom on UI
  scenes.UI.camera.zoom$.subscribe(() => {
    scenes.UI.camera.phaserCamera.setZoom(1);
  });

  const ObjectPoolTypes = {
    Sprite: Phaser.GameObjects.Sprite,
    Graphics: Phaser.GameObjects.Graphics,
    Text: Phaser.GameObjects.Text,
    Group: Phaser.GameObjects.Group,
    Rectangle: Phaser.GameObjects.Rectangle,
  } as const;
  const createObjectPool = (scene: Phaser.Scene) => {
    const map = new Map<string, Phaser.GameObjects.GameObject | Phaser.GameObjects.Group>();

    const getSprite = (id: string) => {
      if (!map.has(id)) {
        const sprite = scene.add.sprite(0, 0, "");
        sprite.setDepth(10);
        sprite.setScale(1, 1);
        sprite.setOrigin(0, 0);
        sprite.setTexture("MainAtlas", "sprites/blank.png");
        sprite.setData("objectPoolId", id);
        map.set(id, sprite);
      }

      return map.get(id);
    };

    const getRect = (id: string) => {
      if (!map.has(id)) {
        const rect = scene.add.rectangle(0, 0, 0, 0, 0);
        rect.setDepth(10);
        rect.setOrigin(0, 0);
        rect.setFillStyle(0x000000, 1);
        rect.setData("objectPoolId", id);
        map.set(id, rect);
      }

      return map.get(id);
    };

    const getGraphics = (id: string) => {
      if (!map.has(id)) {
        const graphics = scene.add.graphics();
        map.set(id, graphics);
      }

      return map.get(id);
    };

    const getText = (id: string) => {
      if (!map.has(id)) {
        const text = scene.add.text(0, 0, "", {});
        map.set(id, text);
      }

      return map.get(id);
    };

    const getGroup = (id: string) => {
      if (!map.has(id)) {
        const group = scene.add.group();
        map.set(id, group);
      }

      return map.get(id);
    };

    type ObjectPoolTypes = {
      [key in keyof typeof ObjectPoolTypes]: InstanceType<(typeof ObjectPoolTypes)[key]>;
    };

    return {
      get: <objectType extends keyof ObjectPoolTypes>(id: string, type: objectType): ObjectPoolTypes[objectType] => {
        if (type === "Sprite") {
          return getSprite(id) as never;
        } else if (type === "Text") {
          return getText(id) as never;
        } else if (type === "Group") {
          return getGroup(id) as never;
        } else if (type === "Rectangle") {
          return getRect(id) as never;
        } else {
          return getGraphics(id) as never;
        }
      },
      remove: (id: string) => {
        const gameObject = map.get(id);
        if (gameObject) {
          gameObject.destroy(true);
          map.delete(id);
        }
      },
      exists: (id: string) => {
        return map.has(id);
      },
    };
  };

  const globalObjectPool = createObjectPool(scenes.Main.phaserScene);
  const globalObjectPoolAbsolute = createObjectPool(scenes.UI.phaserScene);
  function selectAndView(entity: Entity) {
    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const {
      Main: {
        camera,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    } = scenes;
    const pixelPosition = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    camera.phaserCamera.pan(pixelPosition.x, pixelPosition.y, 350, Phaser.Math.Easing.Cubic.InOut);
    // Refresh the camera view right before we pan over so there is no visual stutter
    setTimeout(() => {
      camera.setScroll(camera.phaserCamera.scrollX, camera.phaserCamera.scrollY);
      camera.setZoom(camera.phaserCamera.zoom);
    }, 250);

    selectEntity(entity);
  }

  const LARGE_SCREEN_CUTOFF = 1200;
  function isLargeScreen() {
    return window.innerWidth > LARGE_SCREEN_CUTOFF;
  }

  const uiState = observable({
    hideLoading: false,
    map: {
      fullscreen: false,
    },
  });

  function createMapInteractionApi() {
    const disablers = new Set<string>();

    return {
      disableMapInteraction: (id: string) => {
        disablers.add(id);
      },
      enableMapInteraction: (id: string) => {
        disablers.delete(id);
      },
      forceEnableMapInteraction: () => {
        disablers.clear();
      },
      mapInteractionEnabled: () => {
        return disablers.size === 0;
      },
    };
  }



  const findColoredAnimation = (animationKey: Animations, colorName: string) => {
    const {
      config: { animations },
    } = scenes.Main;

    const tintedAnimationKey = `${animationKey}-${colorName}`;
    const tintedAnimation = animations.find((a) => a.key === tintedAnimationKey);

    return tintedAnimation;
  };

  function playTintedAnimation(
    entity: Entity | string,
    animation: Animations,
    colorName: ValueOf<typeof PLAYER_COLORS>,
    callback?: (gameObject: Phaser.GameObjects.Sprite) => void,
  ) {
    
    const sprite = globalObjectPool.get(entity, "Sprite");
    const finalAnimation = findColoredAnimation(animation, colorName)?.key ?? animation;
    sprite.play(finalAnimation);
    if (callback) callback(sprite);

    return finalAnimation;
  }

  function playAnimationWithOwnerColor(
    entity: Entity,
    animation: Animations,
    callback?: (gameObject: Phaser.GameObjects.Sprite) => void,
  ) {
    
    const color = getOwnerColor(entity, matchEntity);
    return playTintedAnimation(entity, animation, color.name, callback);
  }

  function drawTileHighlight(id: string, position: Coord, isAttackRange: boolean, alpha = 1, attack = false) {
    const {
      maps: {
        Main: { tileHeight, tileWidth },
      },
    } = scenes.Main;

    let animation = Animations.TileOutline;
    if (!attack) {
      if (isAttackRange) animation = Animations.TileOutlineAttackable;
    } else {
      if (isAttackRange) animation = Animations.TileOutlineAttack;
    }

    const sprite = globalObjectPool.get(id, "Sprite");
    const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    sprite.play(animation);
    sprite.setOrigin(0, 0);
    sprite.setPosition(pixelCoord.x, pixelCoord.y);
    sprite.setDepth(RenderDepth.Background1);
    sprite.setAlpha(alpha);
  }

  const depthFromPosition = (position: { x: number; y: number }, depth: RenderDepth) => {
    const baseDepth = (position.y + 100) * 10;
    const finalDepth = baseDepth + depth;

    return finalDepth;
  };

  const setDepth = (entity: Entity, depth: RenderDepth) => {
    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const sprite = globalObjectPool.get(entity, "Sprite");
    sprite.setDepth(depthFromPosition(position, depth));
  };

  const drawSpriteAtTile = (
    id: string,
    spriteId: Sprites,
    tileCoord: WorldCoord,
    depth: RenderDepth,
    options?: { depthPosition?: WorldCoord; yOffset?: number; xOffset?: number },
  ) => {
    const {
      maps: {
        Main: { tileWidth, tileHeight },
      },
      config: { sprites },
    } = scenes.Main;

    const spriteConfig = sprites[spriteId];
    const pixelCoord = tileCoordToPixelCoord(tileCoord, tileWidth, tileHeight);
    const sprite = globalObjectPool.get(id, "Sprite");

    sprite.setOrigin(0, 0);
    sprite.setPosition(pixelCoord.x + (options?.xOffset ?? 0), pixelCoord.y + (options?.yOffset ?? 0));
    sprite.setDepth(depthFromPosition(options?.depthPosition ?? tileCoord, depth));
    sprite.setTexture(spriteConfig.assetKey, spriteConfig.frame);

    return sprite;
  };

  const arrowPainter = createArrowPainter(scenes.Main);
	const layer = {
		world,
    components,
		parentLayers: {
			...local.parentLayers,
			local,
		},
    game,
		scenes,
    api: {
      highlightCoord: (_coord: Coord) => {
        "no-op for types";
      },
      selectAndView,
      mapInteraction: createMapInteractionApi(),
      findColoredAnimation,
      playTintedAnimation,
      playAnimationWithOwnerColor, 
      drawTileHighlight,

      drawSpriteAtTile,
      depthFromPosition,
      setDepth,
      arrowPainter,
      showMenu: (show: boolean) => setComponent(components.Menu, singletonEntity, { show: show })
    },
    uiState,
    globalObjectPool,
    globalObjectPoolAbsolute,
	};
  layer.api.highlightCoord = curry(highlightCoord)(layer);
	// createMapSystem(layer);
  createInputSystem(layer);
  createDrawHighlightCoordSystem(layer);
  createSelectionSystem(layer);
  createSetVisualsSystem(layer);
  createAppearanceSystem(layer);
  createLocalPositionSystem(layer);
  createSpriteAnimationSystem(layer);
  createDrawPotentialPathSystem(layer);
  createDrawNextPositionSystem(layer);
  createHueTintSystem(layer);
  createDrawAttackableEntitiesSystem(layer);
  createFightSystem(layer)
  createTintOnCooldownSystem(layer);
  // createMenuSystem(layer);
  createConstrainCameraSystem(layer);
  createCalculateFightResultSystem(layer);
  createDrawEntityHeader(layer);
	return layer;
}
