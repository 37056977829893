
import { encodeAbiParameters, stringToHex } from "viem";
const erc721ModuleArgs = encodeAbiParameters(
  [
    { type: "bytes14" },
    {
      type: "tuple",
      components: [{ type: "string" }, { type: "string" }, { type: "string" }],
    },
  ],
  [stringToHex("HatredNFT", { size: 14 }), ["No Valuable Token", "NVT", "http://www.example.com/base/uri/goes/here"]],
);

export default {
  modules: [
    // {
    //   artifactPath: "@latticexyz/world-modules/out/PuppetModule.sol/PuppetModule.json",
    //   root: false,
    //   args: [],
    // },
    // {
    //   artifactPath: "@latticexyz/world-modules/out/ERC721Module.sol/ERC721Module.json", 
    //   root: false,
    //   args: [
    //     {
    //       type: "bytes",
    //       value: erc721ModuleArgs,
    //     },
    //   ],
    // },
  ]
};
