import { tileCoordToPixelCoord } from "phaserx";
import { Has, getComponentValueStrict, getComponentValue,UpdateType, defineSystem, Entity, HasValue, runQuery, Not } from "@latticexyz/recs";
import { Animations } from "../../phaserConstants";
import { PhaserLayer,RenderDepth } from "../../types";
import { singletonEntity, decodeEntity } from "@latticexyz/store-sync/recs";
import { TILE_WIDTH, TILE_HEIGHT } from "../../phaserConstants";
import { Sprites } from "../../phaserConstants";
import { fromHex } from "viem";
import { Tileset } from "../../tilesets/overworld";

export function createDrawHighlightCoordSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: {
        config: { sprites },
        maps: {
          Main,
        },
      },
    },
    components: { HoverHighlight },
    parentLayers: {
      local: {
        components: { Selected, LocalPosition },
      },
      network: {
        components: { TerrainType, UnitItems },
      }
    },
    api: { 
      playTintedAnimation 
    },
    globalObjectPool,
  } = layer;
  
  let { tileWidth, tileHeight } = Main
  showEndTurnHighlight();
  function remove(){
    for (let i = 0; i < 10; i++) {
      const itemSprite = globalObjectPool.get(`${singletonEntity}-item-${i}`, "Sprite");
      const highlightSprite = globalObjectPool.get(`${singletonEntity}-item-highlight-${i}`, "Sprite");
      itemSprite.setAlpha(0);
      highlightSprite.setAlpha(0);
      globalObjectPool.remove(`${singletonEntity}-item-${i}`);
      globalObjectPool.remove(`${singletonEntity}-item-highlight-${i}`);
    }
  }
  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    const highlightEntity = `${entity}-hover-highlight` as Entity;
    if (type === UpdateType.Exit) {
      globalObjectPool.remove(highlightEntity);
      remove()
      return;
    }

    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    
    
    const sprite = globalObjectPool.get(highlightEntity, "Sprite");

    const position = { x: hoverHighlght.x, y: hoverHighlght.y };
    const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
    sprite.setPosition(pixelCoord.x, pixelCoord.y);
    playTintedAnimation(highlightEntity, Animations.TileSelect, "white");
    sprite.setDepth(RenderDepth.Foreground1);

    const highlightedEntity =
    hoverHighlght &&
      [...runQuery([HasValue(LocalPosition, { x: hoverHighlght.x, y: hoverHighlght.y }), Not(TerrainType)])][0];
    if (highlightedEntity) {
      const items = getComponentValue(UnitItems, highlightedEntity);
      if (items?.value) {
        for (const [index, item] of items.value.entries()) {
          // Create highlight background
          const highlightSprite = globalObjectPool.get(`${singletonEntity}-item-highlight-${index}`, "Sprite");
          highlightSprite.play(Animations.TileSelect);
          highlightSprite.setDepth(RenderDepth.AlwaysOnTop);
          highlightSprite.setScale(0.4, 0.4);
          highlightSprite.setAlpha(1);

          // Create item sprite
          const sprite = globalObjectPool.get(`${singletonEntity}-item-${index}`, "Sprite");
          const itemNum = fromHex(item, "string").replace("Item", "").trim();
          const itemNumber = parseInt(itemNum);
          if (isNaN(itemNumber)) {
            console.warn(`Invalid item number: ${itemNum}`);
            continue;
          }
          const spriteConfig = sprites[Sprites[`Item${itemNumber}`]];
          sprite.setTexture(spriteConfig.assetKey, spriteConfig.frame);
          sprite.setPosition(pixelCoord.x, pixelCoord.y);
          sprite.setDepth(RenderDepth.AlwaysOnTop);
          sprite.setScale(0.3, 0.3);
          
          // Calculate horizontal offset to center items with padding
          const totalItems = items.value.length;
          const itemWidth = sprite.width * sprite.scaleX;
          const padding = 2; // Reduced padding between items even more
          const totalWidth = (totalItems * itemWidth) + ((totalItems - 1) * padding);
          const startX = pixelCoord.x + (tileWidth - totalWidth) / 2;
          const itemX = startX + (index * (itemWidth + padding));
          
          // Position both sprites
          sprite.setX(itemX);
          sprite.setY(pixelCoord.y - 12);
          sprite.setAlpha(1);

          // Position highlight sprite centered on item sprite
          highlightSprite.setX(itemX - (highlightSprite.width * highlightSprite.scaleX - sprite.width * sprite.scaleX) / 2);
          highlightSprite.setY(pixelCoord.y - 12 - (highlightSprite.height * highlightSprite.scaleY - sprite.height * sprite.scaleY) / 2);
        }
      }
    } else {
      remove();
    }
  });

  function showEndTurnHighlight() {
    const sprite = globalObjectPool.get(singletonEntity, "Sprite");
    const pixelCoord = tileCoordToPixelCoord({x: -7, y: -5}, TILE_WIDTH, TILE_HEIGHT);
    sprite.setPosition(pixelCoord.x-20, pixelCoord.y-50);
    sprite.play(Animations.TileSelectEndTurn);

    const text = globalObjectPool.get(`${singletonEntity}-hover-highlight-txt` as Entity, "Text");
    text.setPosition(pixelCoord.x-20, pixelCoord.y-60);
    text.setDepth(RenderDepth.Foreground1);
    text.setAlpha(1)
    text.setColor("#CA5650");
    text.setText(`End turn`)
    text.setFontStyle("bold");
  }
}
