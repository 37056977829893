import { useStore } from "../useStore";
import { AmalgemaUIRoot } from "./amalgema-ui/AmalgemaUIRoot";
import { LoadingScreen } from "./amalgema-ui/LoadingScreen";

export const Amalgema = () => {
  return <AmalgemaMenu />;
}

const AmalgemaMenu = () => {
  const networkLayer = useStore((state) => state.networkLayer);
  return (
    <div>
      <LoadingScreen networkLayer={networkLayer} />
      <AmalgemaUIRoot />
    </div>
  );
};

