
import { useStore } from "../../useStore";
import { MatchTable } from "./MatchTable/MatchTable";
export const AmalgemaUIRoot = () => {
	const layers = useStore((state) => {
		return {
			networkLayer: state.networkLayer,
		};
	});

	if (!layers.networkLayer) return <></>;

  return (
    <div className="flex h-screen">
      <div className="h-screen flex flex-col grow">
        <div
          style={{
            background:
              "url(/assets/wallpaper.png)",
            backgroundSize: "cover",
            backgroundPosition: "right",
            zIndex: -2,
            filter: "blur(3px)",
            WebkitFilter: "blur(3px)",
          }}
          className="fixed top-0 left-0 h-screen w-screen bg-cover"
        />

        <div className="grow px-8 py-6 flex flex-col">

          <div className="h-6" />

          <MatchTable />
        </div>
      </div>
    </div>
  );
    
}  
