import { useComponentValue } from "@latticexyz/react";
import { useMUD } from "../../useMUD";
import { Entity, getComponentValue } from "@latticexyz/recs";
import { useState } from "react";
import { ClickWrapper } from "./Theme/ClickWrapper";
import { Card } from "./Theme/FireEmblem/Card";
import { Body, OverlineSmall } from "./Theme/FireEmblem/Typography";
import { Button } from "./Theme/FireEmblem/Button";
import { FEEDBACK_URL } from "../links";
export const GameOutcome = ({ matchEntity }: { matchEntity: Entity }) => {
    const {
        networkLayer: {
          components: { MatchFinished, MatchWon },
        },
      } = useMUD();
  const finished = useComponentValue(MatchFinished, matchEntity);
  const won = useComponentValue(MatchWon, matchEntity);
  const homepageParams = new URLSearchParams(window.location.search);
  homepageParams.delete("match");
  return (
    <>
      {(finished && finished.value) && (
        <ClickWrapper
          className="fixed w-screen h-screen top-0 left-0 flex flex-col items-center justify-center bg-[#181710]/60"
          onClick={() => { window.location.href = `/`}}
        >
          <div>
            <div className="relative">
              {won && won.value ? <img className="animate-victory-pulse" src="/assets/result_won.gif"/> : <img className="animate-victory-pulse" src="/assets/result_lost.gif" height={300} width={300}/>}
              <style jsx>{`
                @keyframes victoryPulse {
                  0%, 100% { 
                    transform: scale(1);
                    filter: brightness(100%);
                  }
                  50% { 
                    transform: scale(1.05);
                    filter: brightness(120%);
                  }
                }
                .animate-victory-pulse {
                  animation: victoryPulse 1.5s ease-in-out infinite;
                }
              `}</style>
            </div>
          </div>
        </ClickWrapper>
      )}
    </>
  );
}
