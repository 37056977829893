import "tailwindcss/tailwind.css";
import ReactDOM from "react-dom/client";
import { router } from "./router";
import { Providers } from "./app/Providers";
import { RouterProvider } from "react-router-dom";

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");

const root = ReactDOM.createRoot(rootElement);
root.render(
    <Providers>
        <RouterProvider router={router} />
    </Providers>
);
