
import { defineSceneConfig, AssetType, defineScaleConfig, defineMapConfig, defineCameraConfig } from "phaserx";
import {  Assets, Maps, Scenes, TILE_HEIGHT, TILE_WIDTH } from "./phaserConstants";
import { Tileset, TileAnimations } from "./tilesets/overworld";
import { getAnimations } from "./animationConfig";
import { getSprites } from "./spriteConfig";
import overworldTileset from "../../../public/tilesets/overworld.png";
import overworldTileset1 from "../../../public/tilesets/overworld-1.png";
import tinyWorldTilesetImg from "../../../public/tilesets/tiny-world.png";
import * as assets from './maps';
import { TriggerUpdate } from "phaserx";
import playGuideline from "./guideline";
const ANIMATION_INTERVAL = 200;
export const MAX_CAMERA_ZOOM = 3;
export const MIN_CAMERA_ZOOM = 1;

export const gameConfig = {
	sceneConfig: {
		[Scenes.Main]: defineSceneConfig({
			update: (scene: Phaser.Scene) => {TriggerUpdate(scene, 4)},
			assets: {
				[Assets.OverworldTileset]: { type: AssetType.Image, key: Assets.OverworldTileset, path: overworldTileset },
				[Assets.OverworldTilesetTinyworld]: { type: AssetType.TilemapTiledJSON, path: tinyWorldTilesetImg },
				[Assets.OverworldTilesetTuxemon]: { type: AssetType.TilemapTiledJSON, path: overworldTileset1 },
				[Assets.MainAtlas]: {
				  type: AssetType.MultiAtlas,
				  key: Assets.MainAtlas,
				  // Add a timestamp to the end of the path to prevent caching
				  path: `/atlases/sprites/atlas.json?timestamp=${Date.now()}`,
				  options: {
					imagePath: "/atlases/sprites/",
				  },
				},
			},
			maps: {
				[Maps.Main]: defineMapConfig({
				  chunkSize: TILE_WIDTH * 64, // tile size * tile amount
				  tileWidth: TILE_WIDTH,
				  tileHeight: TILE_HEIGHT,
				  backgroundTile: [],
				  animationInterval: ANIMATION_INTERVAL,
				  tileAnimations: TileAnimations,
				  layers: {
					layers: {
					  Background: { tilesets: ["Default"] },
					  Foreground: { tilesets: ["Default"] },
					},
					defaultLayer: "Background",
				  },
				}),
			  },
			sprites: getSprites(),
			animations: getAnimations(),
			tilesets: {
				Default: { assetKey: Assets.OverworldTilesetTinyworld, tileWidth: TILE_WIDTH, tileHeight: TILE_HEIGHT },
			},
		}),
		[Scenes.UI]: defineSceneConfig({
			create: (scene: Phaser.Scene) => {
				playGuideline(scene);
			},
			assets: {},
			maps: {},
			animations: [],
			tilesets: {},
			sprites: {},
		}),
	},
  scale: defineScaleConfig({
    parent: "phaser-game",
    zoom: 1,
    mode: Phaser.Scale.NONE,
  }),
  cameraConfig: defineCameraConfig({
    pinchSpeed: 1,
    wheelSpeed: 1,
    maxZoom: MAX_CAMERA_ZOOM,
    minZoom: MIN_CAMERA_ZOOM,
  }),
  cullingChunkSize: TILE_HEIGHT * 16,
}
