import { Assets, Sprites } from "./phaserConstants";
import PlayerColors from "../../Local/player-colors.json";

const baseSprites = {
	[Sprites.Druid]: {
		assetKey: Assets.MainAtlas,
		frame: "sprites/greyscale/units/druid/idle/0.png",
	},
	[Sprites.Hero]: {
		assetKey: Assets.MainAtlas,
		frame: "sprites/greyscale/units/hero/idle/0.png",
	},
	[Sprites.General]: {
		assetKey: Assets.MainAtlas,
		frame: "sprites/greyscale/units/general/idle/0.png",
	},
	[Sprites.Paladin]: {
		assetKey: Assets.MainAtlas,
		frame: "sprites/greyscale/units/paladin/idle/0.png",
	},
  [Sprites.WoodenWall]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/greyscale/structures/big/wooden-barricade/0.png",
  },
  [Sprites.GoldMine]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/greyscale/structures/big/generators/metal/0.png",
  },
  [Sprites.Boot]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/greyscale/ui/icons/boot.png",
  },
  // [Sprites.BootConfirm]: {
  //   assetKey: Assets.MainAtlas,
  //   frame: "sprites/greyscale/ui/icons/boot-check.png",
  // },
  [Sprites.Sword]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/fightState/sword.png",
  },

  [Sprites.BarBackground]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/greyscale/ui/bars/background.png",
  },
  [Sprites.HealthBar]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/entity_bars/green-bar.png",
  },
  [Sprites.HealthBarTick]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/entity_bars/green-tick.png",
  },
  [Sprites.HealthBarRed]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/entity_bars/red-bar.png",
  },
  [Sprites.HealthBarRedTick]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/entity_bars/red-tick.png",
  },
  [Sprites.Item1]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_1.png",
  },
  [Sprites.Item2]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_2.png",
  },
  [Sprites.Item3]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_3.png",
  },
  [Sprites.Item4]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_4.png",
  },
  [Sprites.Item5]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_5.png",
  },
  [Sprites.Item6]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_6.png",
  },
  [Sprites.Item7]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_7.png",
  },
  [Sprites.Item8]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_8.png",
  },
  [Sprites.Item9]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_9.png",
  },
  [Sprites.Item10]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_10.png",
  },
  [Sprites.Item11]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_11.png",
  },
  [Sprites.Item12]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_12.png",
  },
  [Sprites.Item13]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_13.png",
  },
  [Sprites.Item14]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_14.png",
  },
  [Sprites.Item15]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_15.png",
  },
  [Sprites.Item16]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_16.png",
  },
  [Sprites.Item17]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_17.png",
  },
  [Sprites.Item18]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_18.png",
  },
  [Sprites.Item19]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_19.png",
  },
  [Sprites.Item20]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_20.png",
  },
  [Sprites.Item21]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_21.png",
  },
  [Sprites.Item22]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_22.png",
  },
  [Sprites.Item23]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_23.png",
  },
  [Sprites.Item24]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_24.png",
  },
  [Sprites.Item25]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_25.png",
  },
  [Sprites.Item26]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_26.png",
  },
  [Sprites.Item27]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_27.png",
  },
  [Sprites.Item28]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_28.png",
  },
  [Sprites.Item29]: {
    assetKey: Assets.MainAtlas,
    frame: "sprites/items/item_29.png",
  },
}
export const getSprites = () => {
	  // add sprites for each player color
	Object.entries(baseSprites).forEach(([key, data]) => {
		if (!data.frame.includes("sprites/greyscale")) return;

		const colorKeys = Object.values(PlayerColors).slice(0, 5);

		colorKeys.forEach((color) => {
			const newSprite = { ...data };

			newSprite.frame = `sprites/tinted_images/${color}/${newSprite.frame.replace("sprites/greyscale/", "")}`;
			baseSprites[`${key}-${color}`] = newSprite;
		});
	});


  /**
   * Arrow sprites are coded depending on the neighboring arrow pieces.
   * Neighbors are coded using this schema:
   * 7 | 0 | 1
   * 6 | x | 2
   * 5 | 4 | 3
   * i.e. A straight arrow piece pointing horizontally with neighbors on both sides would be 26.
   * Numbers are written starting at 0 and going clockwise.
   *
   * Start and end are used because arrow pieces are different in these cases.
   */
  const arrowCodes = [
    "0-end",
    "0-start",
    "02",
    "04",
    "06",
    "2-end",
    "2-start",
    "24",
    "26",
    "4-end",
    "4-start",
    "46",
    "6-end",
    "6-start",
  ];
  for (const arrowCode of arrowCodes) {
    baseSprites[`ArrowMove-${arrowCode}`] = {
      assetKey: Assets.MainAtlas,
      frame: `sprites/arrows/move/${arrowCode}.png`,
    };
  }
	return baseSprites
}
