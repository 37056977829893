import { toLower } from "lodash";
import { UnitTypes } from "../../Network";
import { Animations, Assets, WALK_ANIMATIONS } from "./phaserConstants";
import PlayerColors from "../../Local/player-colors.json";
function createWalkAnimations() {
  const animationConfigs: {
    key: string;
    assetKey: Assets.MainAtlas;
    startFrame: number;
    endFrame: number;
    frameRate: number;
    repeat: number;
    prefix: string;
    suffix: string;
  }[] = [];

  for (const unitTypeString in UnitTypes) {
    const unitType = Number(unitTypeString) as UnitTypes;
    if (!WALK_ANIMATIONS[unitType]) continue;

    const folderName = toLower(UnitTypes[unitType]);
    const walkAnimationKeys = WALK_ANIMATIONS[unitType];
    const directions = ["up", "down", "left", "right"];

    walkAnimationKeys.forEach((key, i) => {
      animationConfigs.push({
        key,
        assetKey: Assets.MainAtlas,
        startFrame: 0,
        // Ranged unit walk animations are only 3 frames long
        // Why? I have no fucking idea
        endFrame: [UnitTypes.Archer, UnitTypes.Catapult, UnitTypes.Marksman].includes(unitType) ? 2 : 3,
        frameRate: 6,
        repeat: -1, repeatDelay: 0,
        prefix: `sprites/greyscale/units/${folderName}/walk/${directions[i]}/`,
        suffix: ".png",
      });
    });
  }

  return animationConfigs;
}
const baseAnimations = [
	{
		key: Animations.TileSelect,
		assetKey: Assets.MainAtlas,
		startFrame: 0,
		endFrame: 0,
		frameRate: 3,
		repeat: -1, repeatDelay: 0,
		prefix: "sprites/greyscale/ui/tile-select/",
		suffix: ".png",
	},
	{
		key: Animations.TileSelectEndTurn,
		assetKey: Assets.MainAtlas,
		startFrame: 1,
		endFrame: 12,
		frameRate: 12,
		repeat: -1, repeatDelay: 0,
		prefix: "sprites/greyscale/ui/tile-select-end-turn/",
		suffix: ".png",
	},
  {
    key: Animations.TileOutline,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 0,
    frameRate: 1,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/tile_ui/outline/",
    suffix: ".png",
  },
  {
    key: Animations.TileOutlineAttackable,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 0,
    frameRate: 1,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/tile_ui/outline-attackable/",
    suffix: ".png",
  },
  {
    key: Animations.TileOutlineAttack,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 3,
    frameRate: 3,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/tile_ui/outline-attack/",
    suffix: ".png",
  },
  {
    key: Animations.GoldMine,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 9,
    frameRate: 4,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/greyscale/structures/big/generators/metal/",
    suffix: ".png",
  },
  {
    key: Animations.HeroIdle,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 9,
    frameRate: 10,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/idle/",
    suffix: ".png",
  },
  {
    key: Animations.HeroAttack,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/attack/",
    suffix: ".png",
  },
  {
    key: Animations.HeroAttackRight,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/attack-right/",
    suffix: ".png",
  },
  {
    key: Animations.HeroAttackLeft,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/attack-left/",
    suffix: ".png",
  },
  {
    key: Animations.HeroAttackUp,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/attack-up/",
    suffix: ".png",
  },
  {
    key: Animations.HeroAttackDown,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/hero/attack-down/",
    suffix: ".png",
  },
  {
    key: Animations.DruidIdle,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 11,
    frameRate: 12,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/idle/",
    suffix: ".png",
  },
  {
    key: Animations.DruidAttack,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 13,
    frameRate: 7,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/attack/",
    suffix: ".png",
  },
  {
    key: Animations.DruidAttackRight,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/attack-right/",
    suffix: ".png",
  },
  {
    key: Animations.DruidAttackLeft,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/attack-left/",
    suffix: ".png",
  },
  {
    key: Animations.DruidAttackUp,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/attack-up/",
    suffix: ".png",
  },
  {
    key: Animations.DruidAttackDown,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/druid/attack-down/",
    suffix: ".png",
  },
  {
    key: Animations.GeneralIdle,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 7,
    frameRate: 8,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/idle/",
    suffix: ".png",
  },
  {
    key: Animations.GeneralAttack,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 35,
    frameRate: 18,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/attack/",
    suffix: ".png",
  },

  {
    key: Animations.GeneralAttackRight,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 8,
    frameRate: 9,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/attack-right/",
    suffix: ".png",
  },
  {
    key: Animations.GeneralAttackLeft,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 8,
    frameRate: 9,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/attack-left/",
    suffix: ".png",
  },
  {
    key: Animations.GeneralAttackUp,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 7,
    frameRate: 8,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/attack-up/",
    suffix: ".png",
  },
  {
    key: Animations.GeneralAttackDown,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 7,
    frameRate: 8,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/general/attack-down/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinIdle,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 9,
    frameRate: 10,
    repeat: -1, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/idle/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinAttack,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 37,
    frameRate: 19,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/attack/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinAttackRight,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/attack-right/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinAttackLeft,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/attack-left/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinAttackUp,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/attack-up/",
    suffix: ".png",
  },
  {
    key: Animations.PaladinAttackDown,
    assetKey: Assets.MainAtlas,
    startFrame: 0,
    endFrame: 5,
    frameRate: 6,
    repeat: 0, repeatDelay: 0,
    prefix: "sprites/greyscale/units/paladin/attack-down/",
    suffix: ".png",
  },
  ...createWalkAnimations(),
]

// generate colored versions of all animations
baseAnimations.forEach((animation) => {
	if (!animation.prefix.includes("sprites/greyscale")) return;
  
	const colorKeys = Object.values(PlayerColors).slice(1, 5);
	colorKeys.forEach((color) => {
	  const newAnimation = { ...animation };
	  newAnimation.key = `${animation.key}-${color}`;
	  newAnimation.prefix = `sprites/tinted_images/${color}/${animation.prefix.replace("sprites/greyscale/", "")}`;
	  baseAnimations.push(newAnimation);
	});
  });
  

export const getAnimations = () => baseAnimations;
