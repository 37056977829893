import { motion } from "framer-motion"

import { ToastContainer, toast } from 'react-toastify';
import { useExternalAccount } from "../hooks/useExternalAccount";
import { useStore } from "../../../useStore";
import { useEntityQuery, useComponentValue } from "@latticexyz/react";
import { Entity, Has, HasValue, Not, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { useAmalgema } from "../../../useAmalgema";
import { decodeEntity, encodeEntity, singletonEntity } from "@latticexyz/store-sync/recs";
import { Hex, fromHex } from "viem";
import { encodeMatchEntity } from "../../../encodeMatchEntity";

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number, offsetLeft: number = 0, offsetRight: number = 0) {
  const start = polarToCartesian(x, y, radius, endAngle - offsetRight)
  const end = polarToCartesian(x, y, radius, startAngle + offsetLeft)
  const largeArcFlag = endAngle - startAngle - offsetLeft - offsetRight <= 180 ? "0" : "1"
  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    "L",
    x,
    y,
    "Z",
  ].join(" ")
  
}

export default function RadialArcMenu({ onMatchCreate, joinableMatches, itemDatas }: { onMatchCreate: () => void, joinableMatches: Entity[] }) {
  const { address: externalAddress } = useExternalAccount();
  const {
		components: { NFTByAddress, NFTContract, NFT, UnitItems, NFTItems },
	} = useAmalgema();
  const { burnerKey } = useStore.getState()
  const menuRadius = 600
  const centerX = menuRadius / 2
  const centerY = menuRadius / 2
  const outerRadius = menuRadius / 2 - (menuRadius * 0.1)
  const innerRadius = outerRadius / 2
  const arcOffset = 0 // Adjust this value to increase/decrease space between arcs
  const centralCircleRadius = innerRadius * 2
  const nftEntities = useEntityQuery([Has(NFT), HasValue(NFT, { mintedBy: externalAddress })])
  // const nfts = useComponentValue(NFTByAddress, encodeEntity({ address: "address" }, { address: (externalAddress as Hex) }));
	const nftContract = useComponentValue(NFTContract, singletonEntity)
  const heroItems = nftEntities.map((nftEntity, index) => {
    let items = []
    if (joinableMatches.length > 0) {
      const matchEntity = joinableMatches[joinableMatches.length-1]
      items = getComponentValue(NFTItems, encodeMatchEntity(matchEntity, nftEntity))
    }
    
    const startAngle = -90 + (180 / nftEntities.length) * index;
    const endAngle = -90 + (180 / nftEntities.length) * (index + 1);
    const nft = getComponentValueStrict(NFT, nftEntity)
    let ii = []
    if (items && items.value) {
      ii = items.value.map((item) => {
        return itemDatas[item]
        // const temp = getComponentValueStrict(Item, item)
        // return temp
      })
    }
    
    return {
      emoji: "🦸",
      hero_type: `${nft.heroType}`,
      hero_name: (() => {
        switch (nft.heroType) {
          case 1:
            return "Warrior";
          case 2:
            return "Goblin"; 
          case 3:
            return "Archer";
          case 4:
            return "Pawn";
          default:
            return "Unknown";
        }
      })(),
      hero_level: `${nft.heroLevel}`,
      nft_id: `${Number(nftEntity)}`,
      items: ii,
      startAngle,
      endAngle,
    };
  });

  const menuItems = [
    { emoji: "👛", hero_type: "wallet", startAngle: 90, endAngle: -90 },
    ...heroItems,
    { emoji: "", hero_type: "No hero", startAngle: -90, endAngle: -45 },
    { emoji: "", hero_type: "No hero", startAngle: -45, endAngle: 0 },
    { emoji: "", hero_type: "No hero", startAngle: 0, endAngle: 45 },
    { emoji: "", hero_type: "No hero", startAngle: 45, endAngle: 90 }
  ].slice(0,5);
  const walletToast = () => {
    navigator.clipboard.writeText(`private key: ${burnerKey} \naddress: ${externalAddress}`);
    toast("Copied your address and private key", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      progressStyle: { background: '#4CAF50' },
    });
  }
  return (
    <div className="flex items-center justify-center h-screen absolute top-0 left-0 w-full z-40">
      <ToastContainer />
      <div className="relative" style={{ width: `${menuRadius}px`, height: `${menuRadius}px` }}>
        <svg width={menuRadius} height={menuRadius} viewBox={`0 0 ${menuRadius} ${menuRadius}`}>
          <defs>
            <filter id="drop-shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="20"/>
              <feOffset dx="10" dy="10" result="offsetblur"/>
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.9"/>
              </feComponentTransfer>
              <feMerge>
                <feMergeNode/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          <g filter="url(#drop-shadow)">
          {/* Background circle */}
          <circle cx={centerX} cy={centerY} r={outerRadius} fill="none" />
          
          {menuItems.map((item, index) => (
            <motion.path
              key={index}
              d={describeArc(
                centerX,
                centerY,
                outerRadius,
                item.startAngle,
                item.endAngle,
                arcOffset,
                arcOffset
              )}
              fill={index == 0 ? "#518496" : "#F0F0F0"}
              opacity={0.9}
              whileHover={{ 
                fill: index == 0 ? "#71B1AB" : "#FFFFFF",
                transition: { duration: 0.1 }
              }}
              style={{ 
                transformOrigin: `${centerX}px ${centerY}px`,
                transformBox: "fill-box",
                cursor: 'pointer'
              }}
              onClick={() => {
                if (index == 0) {
                  walletToast()
                } else if (item.hero_type == "No hero") {
                  toast("Role dice to summon heroes", {
                    position: "top-center",
                    autoClose: 1000,
                  });
                } else {
                  toast.success(<><div>{nftContract?.value}</div><div>{item.hero_name}</div></>, {
                    position: "top-center",
                    autoClose: 1000,
                  });
                }
              }}
            />
          ))}
          
          {/* Inner circle for additional border effect */}
          <circle cx={centerX} cy={centerY} r={innerRadius} fill="#FFF" />
          
          {/* Arc text path for "Welcome to FullyOnChain Studio" */}
          <path
            id="welcomeTextPath"
            d={describeArc(centerX, centerY, outerRadius - innerRadius/2, 70.5, 295.5)}
            fill="none"

          />
          <text className="text-lg font-bold text-white" 
            style={{ cursor: 'pointer', pointerEvents: "none" }}>
            <textPath spacing="auto" fill="#F0F0F0" href="#welcomeTextPath" startOffset="35%" textAnchor="middle">
              🦊 Wallet to play:&nbsp;
              {externalAddress?.substring(0, 5)}...{externalAddress?.substring(externalAddress.length - 3, externalAddress.length)}
            </textPath>
          </text>

          </g>
        </svg>
        {menuItems.map((item, index) => {
          const midAngle = (item.startAngle + item.endAngle) / 2
          const radius = (outerRadius + innerRadius) / 2
          const { x, y } = polarToCartesian(centerX, centerY, radius, midAngle)
          return (
            <div
              key={index}
              className="absolute text-2xl"
              style={{
                left: `${x}px`,
                top: `${y}px`,
                transform: "translate(-50%, -50%)",
                pointerEvents: "none",
              }}
            >
              {item.hero_type == "No hero" && <img src="assets/summoning-2.gif" style={{ width: '80px', height: '80px' }} />}
              {index != 0 && item.hero_type != "No hero" && (
                <div className="relative">
                  <img src={`assets/unit_${item.hero_type}.gif`} style={{ width: '150px', height: '150px' }} />
                  {item.items && item.items.length > 0 && (
                    <div className="absolute top-[10%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-1">
                      {item.items?.map((itemId, idx) => {
                        return itemId?.ItemType?.value ? (
                          <img 
                          key={`${idx}`}
                          src={`/assets/item_${itemId.ItemType.value}.png`}
                          alt={`Item ${itemId.ItemType.value}`}
                          className="w-6 h-6 rounded-sm bg-gradient-to-b from-[#71B1AB] to-[#518496] p-0.5"
                        />
                        ) : null;
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}
        <motion.div
          className="absolute bg-white rounded-full shadow-lg flex items-center justify-center"
          style={{
            width: `${centralCircleRadius}px`,
            height: `${centralCircleRadius}px`,
            left: `${centerX}px`,
            top: `${centerY}px`,
            transform: "translate(-50%, -50%)",            
            cursor: 'pointer',
          }}
        >
          <motion.div 
            onClick={onMatchCreate} 
            className="text-8xl" 
            whileHover={{
              scale: [1, 1.1, 1],
              transition: {
                duration: 0.5,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
          >
            {nftEntities.length == 0 ? <img src="assets/dice-game.gif" style={{ width: '100px', height: '100px' }} /> : <>⚔</>}
          </motion.div> 
        </motion.div>
      </div>
    </div>
  )
}
