import { tileCoordToPixelCoord } from "phaserx";
import {
  ComponentUpdate,
  defineExitSystem,
  defineSystem,
  Entity,
  getComponentValue,
  getComponentValueStrict,
  Has,
  removeComponent,
  UpdateType,
} from "@latticexyz/recs";

import { PhaserLayer, RenderDepth } from "../../types";
import { UNIT_OFFSET } from "../../../../Local/constants";
import { Animations, Sprites, adjust } from "../../phaserConstants";
import { aStar } from "../../../../../utils/pathfinding";
import { worldCoordEq } from "../../../../../utils/coords";
import { UnitTypes } from "../../../../Network";

export function createDrawNextPositionSystem(layer: PhaserLayer) {
	const {
		world,
		components: { SpriteAnimation },
		parentLayers: {
			headless: {
				components: { NextPosition },
			},
			local: {
				api: {
					getOwnerColor, 
					systemDecoders: { onFightState },
				},
				components: { LocalPosition, Path },
			},
			network: {
				components: { Action, UnitType },
				network: { matchEntity },
			},
		},
		scenes: {
			Main: {
				phaserScene,
				maps: {
					Main: { tileWidth, tileHeight },
				},
			},
		},
		globalObjectPool,
		api: {
			playTintedAnimation,
			arrowPainter: { paintArrowAlongPath },
			depthFromPosition,
			drawSpriteAtTile,
		},
	} = layer;

	const attackArrowsByEntity = new Map<Entity, Phaser.GameObjects.Group>();
  const tweens = {} as Record<Entity, Phaser.Tweens.Tween>;

	function drawNextPositionGhost(update: ComponentUpdate & { type: UpdateType }) {
		const { entity, type } = update;

		/**
		 * @notice the arrow from next-position to intended-target-position
		 */
		const lastAttackArrowGroup = attackArrowsByEntity.get(entity);
    attackArrowsByEntity.delete(entity);
    lastAttackArrowGroup?.destroy(true);


		/**
		 * @notice intended-target 
		 */
		const attackSpriteId = `${entity}-attack` as Entity;
		globalObjectPool.remove(attackSpriteId);
		

		/**
		 * @notice ghost sprite to show on destination
		 */
		const spriteId = `${entity}-nextPosition` as Entity;
		globalObjectPool.remove(spriteId);
		if (type === UpdateType.Exit) {
      return;
    }

		const nextPosition = getComponentValue(NextPosition, entity);
		if (!nextPosition) return;

		/**
		 * @notice The animation pre-defined at animation Config + [Phaser]VisualizeSystem
		 * Ex: Halberdier -> HalberdierIdle
		 */
    const animation = getComponentValue(SpriteAnimation, entity);
		
		if (!animation) return;
		const position = getComponentValue(LocalPosition, entity);
		
		if (!position || !worldCoordEq(position, nextPosition)) {
			/**
			 * @notice local-position has not reached to nextPosition yet, then play anim on ghost sprite
			 */
			const color = getOwnerColor(entity, matchEntity);
			playTintedAnimation(spriteId, animation.value as Animations, color.name, (attackSprite) => {
        const pixelCoord = tileCoordToPixelCoord(nextPosition, tileWidth, tileHeight);
        attackSprite.setPosition(pixelCoord.x, pixelCoord.y - UNIT_OFFSET);
				adjust(attackSprite, animation.entityType, "idle")
        attackSprite.setDepth(depthFromPosition({ x: nextPosition.x, y: nextPosition.y }, RenderDepth.Foreground3));
        attackSprite.setAlpha(0.15);
      });
		}

		/**
		 * @notice paint an attack arrow from the NextPosition -> the intended target
		 */
		if (nextPosition.intendedTarget) {
			const intendedTargetPosition = getComponentValue(LocalPosition, nextPosition.intendedTarget);
			if (!intendedTargetPosition) return;

			/** 
			 * @notice this is not a real path, just using aStar to coordinates the
			 * arrow painter expects
			 */ 
      const path = aStar(
        nextPosition,
        intendedTargetPosition,
        100_000,
        (_coord) => 0,
        () => false,
      );

			path.unshift(nextPosition);

      const arrowGroup = paintArrowAlongPath("Attack", path);

      if (arrowGroup)  {
				attackArrowsByEntity.set(entity, arrowGroup);

        // if arrow is already animated recreate it here
        if (tweens[entity]) {
          tweens[entity].destroy();
          tweens[entity] = phaserScene.add.tween({
            targets: arrowGroup.getChildren(),
            ease: "Linear",
            duration: 250,
            repeat: -1,
            alpha: 0,
            yoyo: true,
          });
        }
			}
		}
	}

	defineSystem(world, [Has(NextPosition)], (update) => {
		drawNextPositionGhost(update);
	});


	Action.update$.subscribe((update) => {
		const [currentValue] = update.value;
		if (!currentValue) return;

		const { entity } = currentValue;
		if (!entity) return;

		if (["pending"].includes(currentValue.status)) {
			const nextPosition = getComponentValue(NextPosition, entity);
      if (nextPosition && nextPosition.intendedTarget) {
				const intendedTargetPosition = getComponentValue(LocalPosition, nextPosition.intendedTarget);
				if (intendedTargetPosition) {
					/**
					 * @notice tween the sword on intended target
					 */
					const attackSpriteId = `${entity}-attack` as Entity;
          const sprite = drawSpriteAtTile(attackSpriteId, Sprites.Sword, intendedTargetPosition, RenderDepth.UI2, {
            yOffset: -1 * UNIT_OFFSET,
          });
          phaserScene.add.tween({
            targets: sprite,
            ease: "Linear",
            duration: 250,
            repeat: -1,
            alpha: 0,
            yoyo: true,
          });
				}
			}
		}

		const attackArrow = attackArrowsByEntity.get(entity);
		if (attackArrow && !tweens[entity]) {
			const tween = phaserScene.add.tween({
				targets: attackArrow.getChildren(),
				ease: "Linear",
				duration: 250,
				repeat: -1,
				alpha: 0,
				yoyo: true,
			});
			tweens[entity] = tween;
		}

		/**
		 * @notice tx failed -> remove NextPosition 
		 */
		if (currentValue.status === "failed") {
			const nextPosition = getComponentValue(NextPosition, entity);
			if (nextPosition) {
				/* // TODO: clear incoming damage
				if (nextPosition.intendedTarget) {
          clearIncomingDamage(entity, nextPosition.intendedTarget);
          clearIncomingDamage(nextPosition.intendedTarget, entity);
        }
				 */
				removeComponent(NextPosition, entity);
			}
		}

		
		/**
		 * @notice tx done -> remove NextPosition 
		 */
		if (["failed", "completed"].includes(currentValue.status)) {
      if (tweens[entity]) {
        tweens[entity].destroy();
        delete tweens[entity];

				// TODO: explanation???
        const attackArrow = attackArrowsByEntity.get(entity);
        if (attackArrow) {
          for (const sprite of attackArrow.getChildren()) {
            (sprite as Phaser.GameObjects.Sprite).setAlpha(1);
          }
        }
      }
    }
	});


	/**
	 * @notice remove NextPosition when they stop moving, no longer Path (removed from [Local]PathSystem )
	 */
	defineExitSystem(world, [Has(Path)], ({ entity }) => {
		removeComponent(NextPosition, entity);
	});


	/**
	 * @notice remove NextPosition after getting fightState result
	 */
	onFightState((fightState) => {
    removeComponent(NextPosition, fightState.attacker);
  });

}
