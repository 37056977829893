import { MUDChain, mudFoundry, redstone as mudRedstone } from "@latticexyz/common/chains";
import { parseGwei } from "viem";

export type FireEmblemChain = MUDChain & {
  indexerUrl?: string;
  bridgeUrl?: string;
};

export const garnet = {
  id: 17069,
  name: "Redstone Garnet Testnet",
  network: "garnet",
  summary: {
    location: "Holesky",
  },
  description: "Redstone Garnet Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Holesky Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.garnetchain.com"],
      webSocket: ["wss://rpc.garnetchain.com"],
    },
    public: {
      http: ["wss://rpc.garnetchain.com"],
      webSocket: ["wss://rpc.garnetchain.com"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blockscout",
      url: "https://explorer.garnetchain.com",
    },
  },
  fees: {
    baseFeeMultiplier: 1.2,
    defaultPriorityFee: parseGwei("0.001"),
  },
  indexerUrl: "https://indexer.mud.garnetchain.com",
};

export const redstone = {
  ...mudRedstone,
  indexerUrl: "",
  bridgeUrl: "",
};

const mudFoundryCustom = {
  ...mudFoundry,
  rpcUrls: {
    default: {
      http: ['http://127.0.0.1:8546'],
      webSocket: ['ws://127.0.0.1:8546'],
    },
  },
}
// If you are deploying to chains other than anvil or Lattice testnet, add them here
export const supportedChains: FireEmblemChain[] = [mudFoundryCustom, garnet, redstone];
