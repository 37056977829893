import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { filter, map, merge } from "rxjs";
import {
  componentValueEquals,
  defineQuery,
  getComponentValue,
  Has,
  hasComponent,
  HasValue,
  Not,
  removeComponent,
  runQuery,
  setComponent,
  updateComponent,
} from "@latticexyz/recs";
import { filterNullish } from "@latticexyz/utils";
import { worldCoordEq } from "../../../../../utils/coords";
import { InputUtils } from "./createInputSystem";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { WorldCoord } from "phaserx/src/types";
import { Sprites } from "../../phaserConstants";
export function registerHoverIcon(layer: PhaserLayer, { getSelectedEntity, getHighlightedEntity }: InputUtils) {
	const {
		scenes: {
			Main: { input, maps, phaserScene },
		},
    components: { HoverHighlight, Menu, PreviousHoverHighlight },
		parentLayers: {
      network: {
        components: { TerrainType, Position, UnitItems },
        utils: { isOwnedByCurrentPlayer },
        network: { matchEntity },
      },
      headless: {
        // api: { getMoveAndAttackPath, canAttack },
        components: { NextPosition },
      },
      local: {
        components: { Selected, LocalPosition },
      },
		},
		api: {
			highlightCoord,
      drawSpriteAtTile,
			mapInteraction: { mapInteractionEnabled },
		},
    globalObjectPool,
	} = layer;

  /**
  * 1. Get Unit at highlightedPosition (hovered)
  * 2. Highlight that coord
  * 3. Only react if currently selected a unit 
  */
	const setHoverIcon = function (hoveredPosition: WorldCoord) {
    const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
    const highlightedEntity =
      hoverHighlight &&
      [...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)])][0];
		highlightCoord(hoveredPosition);

    const selectedEntity = getSelectedEntity();
    if (!selectedEntity) {
      input.setCursor("url(public/assets/hover-cursor.png), auto");
      return;
    }
    // TODO: unimplemented
    // if (hasComponent(OnCooldown, selectedEntity)) {
    //   input.setCursor("url(public/assets/default-cursor-3.png), auto");
    //   return;
    // }

    if (!isOwnedByCurrentPlayer(selectedEntity)) {
      input.setCursor("url(public/assets/hover-cursor.png), auto");
      return;
    }
	}

	let lastPointerWorldCoord: WorldCoord | undefined;
  const selectedEntityPositionQuery = defineQuery([Has(Selected), Has(Position)]);
  merge(selectedEntityPositionQuery.update$, input.pointermove$)
    .pipe(
      filter(() => 
        mapInteractionEnabled()
      ),
      map(() => {
        if (!phaserScene.input) return { x: 0, y: 0 };
        
        const pointer = phaserScene.input.activePointer;
        return { x: pointer.worldX, y: pointer.worldY };
      }), // Map pointer to pointer pixel cood
      map((pixel) => {
        const newWorldCoord = pixelToWorldCoord(maps.Main, pixel);
        if (worldCoordEq(newWorldCoord, lastPointerWorldCoord)) {
          return null;
        }

        lastPointerWorldCoord = newWorldCoord;

        return newWorldCoord;
      }), // Map pixel coord to tile coord
      filterNullish(),
    )
    .subscribe((coord) => {
      setHoverIcon(coord);
    });
  setHoverIcon({x: -10, y: 0});  
  // input.keyboard$.subscribe((key) => {
  //   // const component = getComponentValue(Menu, singletonEntity);
  //   // if (!component || !!component.show) {
  //   //   return;
  //   // }
  //   const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
  //   if (!hoverHighlight) {
  //     setHoverIcon({x: -10, y: 0});  
  //     return
  //   }
  //   if (key.isDown) {
  //     if (![87,83,65,68].includes(key.keyCode)) {
  //       return
  //     }
  //     console.log("--a" + key.keyCode)
  //     switch (key.keyCode) {
  //       case 87: setHoverIcon({x: hoverHighlight.x, y: hoverHighlight.y-1});break;   // W
  //       case 83: setHoverIcon({x: hoverHighlight.x, y: hoverHighlight.y+1});break;   // S
  //       case 65: setHoverIcon({x: hoverHighlight.x - 1, y: hoverHighlight.y});break; // A
  //       case 68: setHoverIcon({x: hoverHighlight.x + 1, y: hoverHighlight.y});break; // D
  //     }
  //   }
  // })
  // function currentHovered(): WorldCoord | null {
  //   const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
  //   if (!hoverHighlight) {
  //     setHoverIcon({x: -10, y: 0});  
  //     return null
  //   }
  //   return hoverHighlight
  // }

  // input.onKeyPress(
  //   (keys) => keys.has("W"),
  //   () => {
  //     console.log("W")
  //     const hoverHighlight = currentHovered()
  //     if (!hoverHighlight) return
  //     setHoverIcon({x: hoverHighlight.x, y: hoverHighlight.y-1})
  //   }
  // );
  // input.onKeyPress(
  //   (keys) => keys.has("S"),
  //   () => {
  //     console.log("S")
  //     const hoverHighlight = currentHovered()
  //     if (!hoverHighlight) return
  //     setHoverIcon({x: hoverHighlight.x, y: hoverHighlight.y+1})
  //   }
  // );
  // input.onKeyPress(
  //   (keys) => keys.has("A"),
  //   () => {
  //     console.log("A")
  //     const hoverHighlight = currentHovered()
  //     if (!hoverHighlight) return
  //     setHoverIcon({x: hoverHighlight.x - 1, y: hoverHighlight.y})
  //   }
  // );
  // input.onKeyPress(
  //   (keys) => keys.has("D"),
  //   () => {
  //     console.log("D")
  //     const hoverHighlight = currentHovered()
  //     if (!hoverHighlight) return
  //     setHoverIcon({x: hoverHighlight.x + 1, y: hoverHighlight.y})
  //   }
  // );
}
