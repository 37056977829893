import {
    defineComponent,
    Type,
    namespaceWorld,
    getComponentValue,
    hasComponent,
    runQuery,
    Has,
    HasValue,
    Not,
    getComponentValueStrict,
    Component,
    Entity,
} from "@latticexyz/recs";
import { attack } from "./api";
import { NetworkLayer } from "../Network";
import { createScopeClientToMatchSystem } from "./systems";
import { createPreviousOwnerSystem } from "./systems/PreviousOwnerSystem";
import { createCooldownSystem } from "./systems/CooldownSystem";

import lodash from "lodash";

import { WorldCoord } from "../../types";
import { BigNumber } from "ethers";
import { decodeMatchEntity } from "../../decodeMatchEntity";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { Hex } from "viem";
import { toEthAddress } from "@latticexyz/utils";
import { aStar } from "../../utils/pathfinding";
import PLAYER_COLORS from "../Local/player-colors.json";
import { manhattan } from "../../utils/distance";
const { curry } = lodash;

/**
 * The Headless layer is the second layer in the client architecture and extends the Network layer.
 * Its purpose is to provide an API that allows the game to be played programatically.
 */

export async function createHeadlessLayer(network: NetworkLayer) {
	const world = namespaceWorld(network.network.world, "headless");
  const {
    utils: { isOwnedBy, getOwningPlayer, getLevelSpawns },
    network: {
      components: {
        Movable,
        MoveDifficulty,
        OwnedBy,
        Position,
        StructureType,
        TerrainType,
        UnitType,
        Untraversable,
        PlayerAtIndex,
        MatchConfig,
        Match,
        FightState,
      },
    },
  } = network;  
	const InCurrentMatch = defineComponent(world, { value: Type.Boolean }, { id: "InCurrentMatch" });
  const NextPosition = defineComponent(
    world,
    {
      x: Type.Number,
      y: Type.Number,
      userCommittedToPosition: Type.Boolean,
      intendedTarget: Type.OptionalEntity,
    },
    { id: "NextPosition" },
  );
  const PreviousOwner = defineComponent(world, { value: Type.Entity }, { id: "PreviousOwner" });
  const OnCooldown = defineComponent(world, { value: Type.Boolean }, { id: "OnCooldown" });
	const components = { 
    InCurrentMatch, 
    NextPosition, 
    PreviousOwner,  
    OnCooldown,
  };

  function getOwnerColor(entity: Entity, matchEntity?: Entity | null) {
    const noColor = {
      color: 0xffffff,
      name: "white",
      hex: "ffffff",
    };
    if (matchEntity == null) return noColor;
    let playerEntity: Entity | undefined;
    if (hasComponent(UnitType, entity)) {
      playerEntity = getComponentValue(PreviousOwner, entity)?.value;
    }
    if (!playerEntity) playerEntity = getOwningPlayer(entity);
    if (!playerEntity) return noColor;
    const playerAtIndexKeys = Array.from(
      runQuery([HasValue(PlayerAtIndex, { value: decodeMatchEntity(playerEntity).entity })]),
    )
      .map((entity) => decodeEntity(PlayerAtIndex.metadata.keySchema, entity))
      .filter((key) => key.matchEntity === matchEntity);
    const playerAtIndexKey = playerAtIndexKeys[0];
    if (!playerAtIndexKey) return noColor;
    const matchConfig = getComponentValue(MatchConfig, matchEntity);
    if (!matchConfig) return noColor;
    const spawnsInMatch = getLevelSpawns(matchConfig.mapId);

    spawnsInMatch.sort();
    // const playerIndex = spawnsInMatch.indexOf(playerAtIndexKey.index);
    const playerIndex = Number(playerAtIndexKey.index);
    if (playerIndex === -1) return noColor;
    const colorData = Object.entries(PLAYER_COLORS)[playerIndex + 1];
    return {
      color: parseInt(colorData[0], 16),
      name: colorData[1],
      hex: colorData[0],
    };
  }
  
  const getMoveSpeed = (entity: Entity) => {
    const moveSpeed = getComponentValue(Movable, entity)?.value;
    if (!moveSpeed) return;

    return moveSpeed;
  };

  const isUntraversable = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    playerEntity: Entity,
    isFinalPosition: boolean,
    position: WorldCoord,
  ) => {
    const blockingEntities = runQuery([Has(InCurrentMatch), HasValue(positionComponent, position), Has(Untraversable)]);

    const foundBlockingEntity = blockingEntities.size > 0;
    if (!foundBlockingEntity) return false;
    if (isFinalPosition) return true;

    const blockingEntity = [...blockingEntities][0];

    if (hasComponent(StructureType, blockingEntity)) return true;

    if (!isOwnedBy(blockingEntity, playerEntity)) return true;
    return false;
  };

  const getMovementDifficulty = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    targetPosition: WorldCoord,
  ) => {
    const entity = [...runQuery([HasValue(positionComponent, targetPosition), Has(MoveDifficulty)])][0];
    if (entity == null) return Infinity;

    return getComponentValueStrict(MoveDifficulty, entity).value;
  };

  const calculateMovementPath = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    entity: Entity,
    pos1: WorldCoord,
    pos2: WorldCoord,
  ) => {
    const player = getOwningPlayer(entity);
    const moveSpeed = getMoveSpeed(entity);

    if (!player || !moveSpeed) return [];

    return aStar(
      pos1,
      pos2,
      moveSpeed / 1_000,
      (targetPosition: WorldCoord) => {
        return getMovementDifficulty(positionComponent, targetPosition) / 1_000;
      },
      curry(isUntraversable)(positionComponent, player),
    );
  };



  function getEntitiesInRange(from: WorldCoord, minRange: number, maxRange: number) {
    const entities = [];
    for (let y = from.y - maxRange; y <= from.y + maxRange; y++) {
      for (let x = from.x - maxRange; x <= from.x + maxRange; x++) {
        const distanceTo = manhattan(from, { x, y });
        if (distanceTo >= minRange && distanceTo <= maxRange) {
          const entity = [...runQuery([Has(InCurrentMatch), HasValue(Position, { x: x, y: y }), Not(TerrainType)])][0];
          if (entity) entities.push(entity);
        }
      }
    }
    return entities;
  }

  const getAttackableEntities = (attacker: Entity, atCoord?: WorldCoord) => {
    const attackerOwner = getComponentValue(OwnedBy, attacker);
    if (!attackerOwner) return;

    if (!atCoord) atCoord = getComponentValue(Position, attacker);
    if (!atCoord) return;

    const attackerFightState = getComponentValue(FightState, attacker);
    if (!attackerFightState) return;

    let entities;
    if (attackerFightState.maxRange > 1) {
      entities = getEntitiesInRange(atCoord, attackerFightState.minRange, attackerFightState.maxRange);
    } else {
      entities = getEntitiesInRange(atCoord, 1, 1);
    }

    const attackableEntities: Entity[] = [];
    for (const defender of entities) {
      const fightState = getComponentValue(FightState, defender);
      if (!fightState) continue;

      const defenderOwner = getComponentValue(OwnedBy, defender);
      if (attackerOwner.value === defenderOwner?.value) continue;

      attackableEntities.push(defender);
    }
    return attackableEntities;
  };

  const canAttack = (attacker: Entity, defender: Entity) => {
    /* // TODO: unimplemented. turn-based
    const onCooldown = getComponentValue(OnCooldown, attacker);
    if (onCooldown) return false; */

    const attackerOwner = getComponentValue(OwnedBy, attacker);
    const defenderOwner = getComponentValue(OwnedBy, defender);

    if (!attackerOwner) return false;
    if (attackerOwner.value === defenderOwner?.value) return false;

    const attackerFightState = getComponentValue(FightState, attacker);
    if (!attackerFightState) return false;

    const defenderFightState = getComponentValue(FightState, defender);
    if (!defenderFightState) return false;

    const attackerPosition = getComponentValue(Position, attacker);
    if (!attackerPosition) return;

    const defenderPosition = getComponentValue(Position, defender);
    if (!defenderPosition) return;

    const distanceToTarget = manhattan(attackerPosition, defenderPosition);

    if (distanceToTarget > attackerFightState.maxRange || distanceToTarget < attackerFightState.minRange) return false;

    return true;
  };
	const layer = {
		world,
    parentLayers: { network },
    components,
    api: { 
      attack: curry(attack)({ network }),
      getOwnerColor, 
      getMoveSpeed,
      isUntraversable,
      getMovementDifficulty,

      calculateMovementPath,
      getAttackableEntities,
      canAttack,
    },
	}
	createScopeClientToMatchSystem(layer);
  createPreviousOwnerSystem(layer);
  createCooldownSystem(layer);
	return layer;
}
