import { defineComponent, Type, World } from "@latticexyz/recs";
import { defineAppearanceComponent } from "./Appearance";
import { defineHueTintComponent } from "./HueTint";
import { defineSpriteAnimationComponent } from "./SpriteAnimation";
export function createPhaserComponents(world: World) {
	const Appearance = defineAppearanceComponent(world);
	const SpriteAnimation = defineSpriteAnimationComponent(world);
	const HoverHighlight = defineComponent(world, { x: Type.Number, y: Type.Number }, { id: "HoverHighlight" });
	const HueTint = defineHueTintComponent(world);
	const PreviousHoverHighlight = defineComponent(
		world,
		{ x: Type.Number, y: Type.Number },
		{ id: "PreviousHoverHighlight" },
	);
	const Menu = defineComponent(world, {show: Type.Boolean}, { id: "Menu" });
	const PreviousMovableHightlight = defineComponent(world, { x: Type.Number, y: Type.Number }, { id: "PreviousMovableHighlight" });

	const HeaderHeight = defineComponent(world, { value: Type.Number }, { id: "HeaderHeight" });
	return {
		Appearance,
		SpriteAnimation,
		HoverHighlight,
		PreviousHoverHighlight,
		PreviousMovableHightlight,
		HueTint,
		Menu,
		HeaderHeight,
	};
}
