import { useEffect, useState } from "react";
import { useAmalgema } from "../../../useAmalgema";
import { useComponentValue } from "@latticexyz/react";
import { singletonEntity } from "@latticexyz/store-sync/recs";

export default function MatchItems({itemDatas, isRandomizing}) {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  useEffect(() => {
    let interval;
    if (isRandomizing) {
      interval = setInterval(() => {
        setHighlightedIndex(Math.floor(Math.random() * Object.entries(itemDatas).length));
      }, 200);
    }
    return () => clearInterval(interval);
  }, [isRandomizing]);
  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">Items</h2>
      <div className="grid grid-cols-5 gap-2">
        {Object.entries(itemDatas).map(([key, i], index) => {
          return (
            <div
              key={i.ItemType.value}
              className={`p-2 border rounded cursor-pointer hover:bg-gray-50 border-gray-200 ${
                isRandomizing && index === highlightedIndex ? 'bg-[#71B1AB] bg-opacity-50 border-2 border-[#518496]' : ''
              }`}
              onMouseEnter={(e) => {
                const tooltip = document.createElement('div');
                tooltip.className = 'absolute bg-gradient-to-b from-[#71B1AB] to-[#518496] border border-[#71B1AB] rounded-lg p-4 shadow-xl z-50 text-white';
                tooltip.innerHTML = `
                  <div class="text-base">
                    <p class="font-bold text-lg mb-2">Item ${i.ItemType.value}</p>
                    ${
                    Object.keys(i).filter(key => key !== 'ItemType').map(key => (
                      `<div class="flex items-center gap-2 mb-1">
                        <img src="/assets/${key.toLowerCase()}.png" alt="${key}" class="w-6 h-6"/>
                        <p class=" text-white"> + ${i[key].value}</p>
                      </div>`
                    )).join('')
                    }
                  </div>
                `;
                
                // Calculate position relative to panel width
                const panelWidth = 400; // Width of the panel from MatchTable.tsx
                const tooltipX = e.clientX;
                const tooltipY = e.clientY;
                
                // Position tooltip to left if near right edge
                tooltip.style.left = tooltipX + 100 > window.innerWidth 
                  ? `${tooltipX - 100}px`
                  : `${tooltipX + 15}px`;
                tooltip.style.top = `${tooltipY}px`;
                
                tooltip.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                tooltip.id = `tooltip-${i}`;
                document.body.appendChild(tooltip);
              }}
              onMouseLeave={() => {
                const tooltip = document.getElementById(`tooltip-${i}`);
                if (tooltip) tooltip.remove();
              }}
            >
              <img
                src={`/assets/item_${i.ItemType.value}.png`}
                alt={`Item ${i}`}
                className="w-full h-auto"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
