
import { useStore } from "../../useStore";
import styled from "styled-components";
import { Body } from "./Theme/FireEmblem/Typography";
import { GameOutcome } from "./GameOutcome";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { defineSystem, getComponentValue, Has } from "@latticexyz/recs";
import { useEffect } from "react";

const Layer = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`;

export const UIRoot = () => {
	const layers = useStore((state) => {
		return	 {
			networkLayer: state.networkLayer,
			headlessLayer: state.headlessLayer,
			localLayer: state.localLayer,
			phaserLayer: state.phaserLayer,
		};
	});
	useEffect(() => {
		if (!layers.networkLayer || !layers.headlessLayer || !layers.localLayer || !layers.phaserLayer) return;
		const { world, components: { Transaction } } = layers.networkLayer;
		defineSystem(world, [Has(Transaction)], ({ entity, type }) => {
			const tx = getComponentValue(Transaction, entity)
			if (tx?.hash && tx?.completedBlock){
				toast(`Tx ${tx?.hash.slice(0, 8)}...${tx?.hash.slice(-6)} confirmed`, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		})
	}, [layers.networkLayer, layers.headlessLayer, layers.localLayer, layers.phaserLayer])
	if (!layers.networkLayer || !layers.headlessLayer || !layers.localLayer || !layers.phaserLayer) return <></>;

	const { matchEntity } = layers.networkLayer.network;
	
	return (
		<div>
			<ToastContainer />
			<Layer style={{ inset: "24px", overflow: "hidden" }}>
				{matchEntity ? (
          <div>
            <GameOutcome matchEntity={matchEntity} />
          </div>
        ) : (
          <Body className={`fixed text-black bg-ss-bg-0 rounded-lg p-4 w-96 h-96`}>
            <div>match ID missed</div>
          </Body>
        )}
			</Layer>
		</div>
	)
}
