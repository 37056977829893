import React, { useEffect, useMemo, useState } from "react";
import { getComponentValue } from "@latticexyz/recs";
import { concat, map } from "rxjs";
import { useObservableValue } from "../../useObservableValue";
import { filterNullish } from "@latticexyz/utils";
import { NetworkLayer } from "../../layers/Network";
import { Button } from "../ui/Theme/FireEmblem/Button";
import { Body, Caption, Link, OverlineLarge } from "../ui/Theme/FireEmblem/Typography";
import { Card } from "../ui/Theme/FireEmblem/Card";
import { SyncStep } from "@latticexyz/store-sync";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { DateTime } from "luxon";
import { useStore } from "../../useStore";

type Props = {
  networkLayer: NetworkLayer | null;
  usePrepTime?: boolean;
};

export const LoadingScreen = ({ networkLayer, usePrepTime }: Props) => {
  const { loadingPageHidden: hide } = useStore();
  const setHide = (h: boolean) => {
    useStore.setState({ loadingPageHidden: h });
  };

  const [isMatch, setIsMatch] = React.useState(false);

  const [prepareGameProgress, setPrepareGameProgress] = useState(0);
  const [startGameProgress, setStartGameProgress] = useState(false);

  const loadingState = useObservableValue(
    useMemo(() => {
      if (!networkLayer) return;
      const {
        components: { SyncProgress },
        network: { matchEntity },
      } = networkLayer;

      setIsMatch(Boolean(matchEntity));

      // use LoadingState.update$ as a trigger rather than a value
      // and concat with an initial value to trigger the first look up
      return concat([1], SyncProgress.update$).pipe(
        map(() => {
          const loadingState = getComponentValue(SyncProgress, singletonEntity);
          return loadingState ?? null;
        }),
        filterNullish(),
      );
    }, [networkLayer]),
    {
      message: "Connecting",
      percentage: 0,
      step: SyncStep.INITIALIZE,
      lastBlockNumberProcessed: 0n,
      latestBlockNumber: 0n,
    },
  );

  const [worldValid, setWorldValid] = useState(false);
  useEffect(() => {
    if (!networkLayer) return;
    if (loadingState.step !== SyncStep.LIVE) return;

    if (!usePrepTime || prepareGameProgress === 100) {
      // const {
      //   components: { SkyPoolConfig },
      // } = networkLayer;

      // // check if there is a value for a table that is only available after the game is ready
      // // SkyPoolConfig is set in the PostDeploy script
      // // if it does not exist something is wrong
      // const skyPoolConfig = getComponentValue(SkyPoolConfig, singletonEntity);
      // setWorldValid(!!skyPoolConfig);
      setWorldValid(true)
    }
  }, [loadingState.step, networkLayer, prepareGameProgress, usePrepTime]);

  useEffect(() => {
    if (!usePrepTime) return;
    if (!networkLayer) return;
    if (loadingState.step !== SyncStep.LIVE) return;

    setStartGameProgress(true);
  }, [loadingState, networkLayer, prepareGameProgress, usePrepTime]);

  const prepTimeSeconds = import.meta.env.PROD ? 10 : 1;
  useEffect(() => {
    if (!startGameProgress) return;

    const interval = setInterval(
      () => {
        setPrepareGameProgress((prev) => {
          if (prev === 100) {
            clearInterval(interval);
            return prev;
          }
          return prev + 1;
        });
      },
      (prepTimeSeconds * 1000) / 100,
    );

    return () => clearInterval(interval);
  }, [networkLayer, prepTimeSeconds, startGameProgress, usePrepTime]);

  const doneLoading = usePrepTime ? prepareGameProgress === 100 : loadingState.step === SyncStep.LIVE;
  useEffect(() => {
    if (doneLoading && worldValid) setHide(true);
  }, [doneLoading, isMatch, worldValid]);

  const showPrepMessage = loadingState.step === SyncStep.LIVE && usePrepTime;

  const loadingMessage = showPrepMessage ? "Preparing Game" : loadingState.message;
  const loadingPercentage = showPrepMessage ? prepareGameProgress : Math.round(loadingState.percentage);
  const showPercentage = showPrepMessage || loadingPercentage > 0;

  if (hide) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 1200,
        background: "linear-gradient(rgba(24, 23, 16, 0.4), rgba(24, 23, 16, 0.4)), url(assets/wallpaper.png)",
        backgroundPosition: "right",
        backgroundSize: "cover",
      }}
      className="fixed items-center justify-center w-screen h-screen bg-black p-12 flex flex-col"
    >
      <Card primary className="flex flex-col w-[540px] p-8 justify-items">
        {doneLoading && !worldValid && (
          <div className="flex flex-row w-full mt-8 justify-center items-center">
            <Body className="text-center text-3xl text-ss-text-default">
              {import.meta.env.DEV
                ? "The connected Contract world is not valid. Contract deployment is ongoing or failed. Check your console for more information."
                : "Something went wrong. Please report this issue on Discord."}
            </Body>
          </div>
        )}

        {!doneLoading && (
            <div
              style={{
                zIndex: 1200,
                background: "linear-gradient(rgba(24, 23, 16, 0.8), rgba(24, 23, 16, 0.8)), url(assets/wallpaper.png)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backdropFilter: "blur(5px)",
              }}
              className="fixed inset-0 flex items-center justify-center w-screen h-screen"
            >
            <div className="relative">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-ss-blue"></div>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-ss-blue text-xl font-bold">
                <span style={{ color: loadingMessage === "Preparing Game" ? "#4CAF50" : "#2196F3" }}>
                  {loadingPercentage}%
                </span>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
