import { createNetworkLayer } from "./createNetworkLayer";

export type NetworkLayer = Awaited<ReturnType<typeof createNetworkLayer>>;
export type NetworkComponents = NetworkLayer["network"]["components"];


export enum UnitTypes {
	Unknown,
  Hero,
  Druid,
  General,
  Paladin,
}

export const UnitTypeNames: Record<number, string> = {
  [UnitTypes.Unknown]: "Unknown",
};

export const UnitTypeDescriptions: Record<number, string> = {
  [UnitTypes.Unknown]: "Unknown",
};


export enum StructureTypes {
  Unknown,
  Settlement,
  SpawnSettlement,
  WoodenWall,
  GoldMine,
}

export const StructureTypeNames: Record<number, string> = {
  [StructureTypes.SpawnSettlement]: "Spawn Settlement",
};


export enum TerrainTypes {
  Unknown,
  Grass,
  Mountain,
  Forest,
}

export const TerrainTypeNames: Record<number, string> = {
  [TerrainTypes.Grass]: "Grass",
  [TerrainTypes.Mountain]: "Mountain",
  [TerrainTypes.Forest]: "Forest",
};


