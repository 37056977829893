import {
  Has,
  defineSyncSystem,
  setComponent,
  defineComponentSystem,
  removeComponent,
  getComponentValue,
} from "@latticexyz/recs";
import { LocalLayer } from "../../types";
import { UnitTypeNames, StructureTypeNames, TerrainTypeNames } from "../../../Network/types";

/**
 * The Sync system handles adding Local layer components to entites based on components they have on parent layers
 */
export function createSyncSystem(layer: LocalLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { UnitType, TerrainType, StructureType, FightState},
      },
    },
    components: { Selectable, Interactable, LocalHealth },
  } = layer;

  defineSyncSystem(
    world,
    [Has(UnitType)],
    () => Selectable,
    () => ({ value: true }),
  );

  defineSyncSystem(
    world,
    [Has(StructureType)],
    () => Selectable,
    () => ({ value: true }),
  );

  defineSyncSystem(
    world,
    [Has(UnitType)],
    () => Interactable,
    () => ({ value: true }),
  );

  defineSyncSystem(
    world,
    [Has(StructureType)],
    () => Interactable,
    () => ({ value: true }),
  );
  defineComponentSystem(world, FightState, ({ entity, value }) => {
    const [newValue] = value;
    if (!newValue) return;

    const newHealth = newValue.health;
    const currentHealth = getComponentValue(LocalHealth, entity)?.value;
    if (currentHealth === undefined || newHealth > currentHealth)
      setComponent(LocalHealth, entity, { value: newValue.health });
  });

}
