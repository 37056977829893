export default {
  components: {
    LastNFT: {
      key: [],
      schema: {
        value: "uint32",
      },
    },
    NFTByAddress: {
      key: ["playerAddress"],
      schema: {
        playerAddress: "address",
        nfts: "uint32[]",
        heroes: "uint32[]",
      },
    },
    NFTContract: {
      key: [],
      schema: {
        value: "address",
      },
    },
    NFT: {
      key: ["id"],
      schema: {
        id: "uint32",
        heroType: "uint32",
        heroLevel: "uint32",
        mintedBy: "address",
      },
    }
  }
}
