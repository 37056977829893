import { Has, getComponentValueStrict, defineSystem, Not, setComponent, HasValue, NotValue } from "@latticexyz/recs";
import { StructureTypes } from "../../../../Network";
import { PhaserLayer } from "../../types";
import { drawGoldBar, drawPlayerColorBanner } from "./drawingUtils";
import { createDrawHealthBarSystem } from "./createDrawHealthBarSystem";

export function createDrawEntityHeader(layer: PhaserLayer) {
  const {
    world,
    components: { HeaderHeight },
    parentLayers: {
      network: {
        components: {  },
      },
      local: {
        components: {  },
      },
      headless: {
        components: { InCurrentMatch },
      },
    },
  } = layer;

  defineSystem(world, [Has(InCurrentMatch)], ({ entity }) => {
    const yOffset = +2;
    setComponent(HeaderHeight, entity, { value: yOffset });
  });

  // Health Bar Systems
  createDrawHealthBarSystem(layer);
}
