import {
  defineSystem,
  getComponentValueStrict,
  Has,
  removeComponent,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { Assets, TILE_HEIGHT } from "../../phaserConstants";
import { PhaserLayer, RenderDepth } from "../../types";
import { singletonEntity } from "@latticexyz/store-sync/recs";

export function createConstrainCameraSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: {
        components: { LocalPosition },
      },
      network: {
        components: { TerrainType },
      },
    },
    scenes: {
      Main: {
        camera,
        phaserScene,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  let maxX = -Infinity;
  let maxY = -Infinity;
  let minX = Infinity;
  let minY = Infinity;

  let backgroundTiles: Phaser.GameObjects.Group | undefined;
  let clouds: Phaser.GameObjects.Group | undefined;
  const BACKGROUND_TILE_SIZE = {
    x: 1500,
    y: 1000,
  };

  defineSystem(world, [Has(LocalPosition), Has(TerrainType)], ({ type, entity }) => {
    if (type === UpdateType.Exit) return;

    const position = getComponentValueStrict(LocalPosition, entity);

    let boundsChanged = false;
    if (position.x > maxX) {
      boundsChanged = true;
      maxX = position.x;
    }
    if (position.y > maxY) {
      boundsChanged = true;
      maxY = position.y;
    }
    if (position.x < minX) {
      boundsChanged = true;
      minX = position.x;
    }
    if (position.y < minY) {
      boundsChanged = true;
      minY = position.y;
    }

    // TODO: Hack setBounds for specific tinyworld map for extra tiles
    if (boundsChanged) {
      camera.phaserCamera.setBounds(
        minX * tileWidth,
        minY * tileHeight,
        (maxX + Math.abs(minX)) * tileWidth + TILE_HEIGHT * 2,
        (maxY + Math.abs(minY)) * tileWidth + TILE_HEIGHT * 2
      );

      const centerX = minX - maxX / 2;
      const centerY = minY - maxY / 2;
      camera.phaserCamera.centerOn(centerX, centerY);
    }
  });
}
