import {
  setComponent,
  getComponentValue,
  defineComponent,
  Type,
  namespaceWorld,
  runQuery,
  Has,
  removeComponent,
  getComponentValueStrict,
  defineRxSystem,
  HasValue,
  ComponentValue,
  SchemaOf,
  hasComponent,
  defineExitQuery,
  getComponentEntities,
  Entity,
  NotValue,
} from "@latticexyz/recs";
import { HeadlessLayer } from "../Headless";
import {
  defineLocalPositionComponent,
  defineSelectionComponent,
  defineSelectedComponent,
  defineSelectableComponent,
  definePathComponent
} from "./components";
import {
  createPositionSystem,
  createSyncSystem,
  createSelectionSystem,
  createPotentialPathSystem,
  createPathSystem,
  createAttackableEntitiesSystem,
} from "./systems";
import { Area, awaitStreamValue, sleep } from "@latticexyz/utils";
import { WorldCoord } from "../../types";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { concatMap, merge } from "rxjs";
import { curry } from "lodash";
import { BFS } from "../../utils/pathfinding";
import { decodeMatchEntity } from "../../decodeMatchEntity";
import { encodeMatchEntity } from "../../encodeMatchEntity";
import { isUntraversable } from "../Network/utils";
export async function createLocalLayer(headless: HeadlessLayer) {
  const {
    parentLayers: {
      network: {
        components: { FightOutcome, UnitType },
        utils: { getOwningPlayer, isOwnedByCurrentPlayer },
        api: { move: moveApi, moveThenFight, botReact: botReactApi},
      },
    },
    components: headlessComponents,
    api: { calculateMovementPath, getOwnerColor, getMoveSpeed, getMovementDifficulty },
  } = headless;
	const world = namespaceWorld(headless.parentLayers.network.network.world, "local");
  const LocalPosition = defineLocalPositionComponent(world);
  const MatchStarted = defineComponent(world, { value: Type.Boolean }, { id: "MatchStarted" });
  const Selection = defineSelectionComponent(world);
  const Selected = defineSelectedComponent(world);
  const Selectable = defineSelectableComponent(world);
  const Interactable = defineComponent(world, { value: Type.Boolean }, { id: "Interactable" });
  const Path = definePathComponent(world);
  const PotentialPath = defineComponent(
    world,
    { x: Type.NumberArray, y: Type.NumberArray, costs: Type.NumberArray },
    { id: "PotentialPath" },
  );
  const LocalHealth = defineComponent(world,{ value: Type.Number, }, { id: "LocalHealth" });
  const AttackableEntities = defineComponent(world, { value: Type.EntityArray }, { id: "AttackableEntities" });
  const IncomingDamage = defineComponent(
    world,
    {
      sources: Type.EntityArray,
      values: Type.NumberArray,
      commitments: Type.NumberArray,
    },
    { id: "IncomingDamage" },
  );

  const components = {
    LocalPosition,
    MatchStarted,
    Interactable,
    Selectable,
    Selected,
    Selection,
    Path,
    PotentialPath,
    AttackableEntities,
    LocalHealth,
    IncomingDamage
  }

  setComponent(Selection, singletonEntity, { x: 0, y: 0, width: 0, height: 0 });
  // API
  function selectArea(area: Area | undefined) {
    setComponent(Selection, singletonEntity, area ?? { x: 0, y: 0, width: 0, height: 0 });
  }

  function resetSelection(removeNextPosition = true) {
    if (removeNextPosition) {
      const currentlySelectedEntity = [...runQuery([Has(Selected)])][0];
      if (currentlySelectedEntity) removeComponent(headlessComponents.NextPosition, currentlySelectedEntity);
    }

    setComponent(Selection, singletonEntity, { x: 0, y: 0, width: 0, height: 0 });
  }

  function selectEntity(entity: Entity) {
    for (const entity of getComponentEntities(Selected)) {
      // removeComponent(headlessComponents.NextPosition, entity);
      removeComponent(Selected, entity);
    }

    if (getComponentValue(Selectable, entity)) setComponent(Selected, entity, { value: true });
  }

  const getPotentialPaths = (entity: Entity) => {
    const {
      parentLayers: {
        headless: {
          api: { isUntraversable: isUntraversableHeadless },
        },
      },
    } = layer;
    const moveSpeed = getMoveSpeed(entity);
    if (!moveSpeed) return;

    const localPosition = getComponentValue(LocalPosition, entity);
    if (!localPosition) return;

    const playerEntity = getOwningPlayer(entity) ?? ("0" as Entity);
    const xArray: number[] = [];
    const yArray: number[] = [];

    const [paths, costs] = BFS(
      localPosition,
      moveSpeed,
      curry(getMovementDifficulty)(LocalPosition),
      curry(isUntraversableHeadless)(LocalPosition, playerEntity),
    );

    for (const coord of paths) {
      xArray.push(coord.x);
      yArray.push(coord.y);
    }

    const potentialPaths = {
      x: xArray,
      y: yArray,
      costs: costs,
    };

    return potentialPaths;
  }

  const move = async (entity: Entity, targetPosition: WorldCoord, attackTarget?: Entity) => {
    if (!isOwnedByCurrentPlayer(entity)) return;

    const { NextPosition } = headlessComponents;

    /* 
      TODO: cooldown -> turn-based
      if (hasComponent(OnCooldown, entity)) {
        console.warn("on cooldown");
        removeComponent(NextPosition, entity);
        return;
      }
    */
    const currentPosition = getComponentValue(LocalPosition, entity);
    if (!currentPosition) {
      console.warn("no current position");
      removeComponent(NextPosition, entity);
      return;
    }

    const path = calculateMovementPath(LocalPosition, entity, currentPosition, targetPosition);
    if (path.length == 0) {
      console.warn("no path found from", currentPosition, "to", targetPosition);
      removeComponent(NextPosition, entity);
      return;
    }
    try {
      attackTarget ? await moveThenFight(entity, path, attackTarget) : await moveApi(entity, path);
    } catch (e) {
      console.error(e);
      removeComponent(NextPosition, entity);
    }
  }

  const botReact = async (entities: Entity[], targetPositions: WorldCoord[], attackTargets?: Entity[]) => {
    const { NextPosition } = headlessComponents;

    if (entities.length !== targetPositions.length || (attackTargets && entities.length !== attackTargets.length)) {
      console.warn("Mismatch in number of entities, positions, or attack targets");
      return;
    }

    const paths: WorldCoord[][] = [];

    for (let i = 0; i < entities.length; i++) {
      if (targetPositions[i].x == 123456 && targetPositions[i].y == 123456) {
        paths.push([])
      }
      const entity = entities[i];
      const targetPosition = targetPositions[i];

      const currentPosition = getComponentValue(LocalPosition, entity);
      if (!currentPosition) {
        console.warn(`No current position for entity ${entity}`);
        removeComponent(NextPosition, entity);
        continue;
      }

      const path = calculateMovementPath(LocalPosition, entity, currentPosition, targetPosition);
      if (path.length === 0) {
        console.warn(`No path found from ${currentPosition} to ${targetPosition} for entity ${entity}`);
        removeComponent(NextPosition, entity);
        continue;
      }

      paths.push(path);
    }
    
    try {
      const zeroAddress = '0x0000000000000000000000000000000000000000000000000000000000000000';
      const finalAttackTargets = attackTargets 
        ? attackTargets.map(target => target || zeroAddress as Entity)
        : entities.map(() => zeroAddress as Entity);
      await botReactApi(entities, paths, finalAttackTargets);
    } catch (e) {
      console.error(e);
      entities.forEach(entity => removeComponent(NextPosition, entity));
    }
  }

  const onFightState = (
    callback: (fightStateResult: {
      attacker: Entity;
      defender: Entity;
      attackerDamageReceived: number;
      defenderDamageReceived: number;
      attackerDamage: number;
      defenderDamage: number;
      ranged: boolean;
      attackerDied: boolean;
      defenderDied: boolean;
      defenderCaptured: boolean;
    }) => void,
  ) => {
    const stoppedMoving$ = defineExitQuery([Has(Path)]);
    const triggerMoveAndAttack$ = merge(FightOutcome.update$).pipe(
      concatMap(async (update) => {
        const { value } = update;
        const [fightStateResult] = value;
        if (!fightStateResult) return;

        const { matchEntity } = decodeMatchEntity(update.entity);
        const attacker = encodeMatchEntity(matchEntity, fightStateResult.attacker);
        const defender = encodeMatchEntity(matchEntity, fightStateResult.defender);
        if (hasComponent(Path, attacker)) {
          const pathRemovedPromise = awaitStreamValue(stoppedMoving$, ({ entity }) => {
            return attacker === entity;
          });
          const fallbackTimeoutPromise = sleep(1_500);
          await Promise.any([pathRemovedPromise, fallbackTimeoutPromise]);
        }
        return {
          attacker,
          defender,
          attackerDamageReceived: fightStateResult.attackerDamageReceived,
          defenderDamageReceived: fightStateResult.defenderDamageReceived,
          attackerDamage: fightStateResult.attackerDamage,
          defenderDamage: fightStateResult.defenderDamage,
          ranged: fightStateResult.ranged,
          attackerDied: fightStateResult.attackerDied,
          defenderDied: fightStateResult.defenderDied,
          defenderCaptured: fightStateResult.defenderCaptured,
        };
      }),
    );

    defineRxSystem(world, triggerMoveAndAttack$, (fightStateResult) => {
      if (!fightStateResult) return;
      callback(fightStateResult);
    });
  }

  // Layer
  const layer = {
    world,
    components,
    parentLayers: { ...headless.parentLayers, headless },
    api: {
      selectEntity,
      selectArea,
      resetSelection,
      getOwnerColor,
      getPotentialPaths,
      move,
      botReact,
      systemDecoders: {
        onFightState,
      },
    },
  };
  createSelectionSystem(layer);
  createSyncSystem(layer);
  createPositionSystem(layer);
  createPotentialPathSystem(layer);
  createPathSystem(layer);
  createAttackableEntitiesSystem(layer);
  return layer;
}
